import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from './helpers/customFetchBase.js';
import { type BaseUserMDTemplate, type UserMDTemplate } from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';

export interface DeleteResponse {
  success: boolean;
}

const clearCache = async () => {
  const cache = await caches.open('templates');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

export const templatesApi = createApi({
  reducerPath: 'templates',
  baseQuery: customFetchBase,
  tagTypes: ['templates'],
  endpoints: (builder) => ({
    getTemplateById: builder.query<UserMDTemplate, string>({
      query: (id) => `/auth/u/t/${id}`,
      providesTags: (result) => (result ? providesList([result!], 'templates', { returnList: false }) : []),
    }),
    deleteTemplate: builder.mutation<DeleteResponse, string>({
      query: (id) => ({ url: `/auth/u/t/${id}`, method: 'DELETE' }),
      invalidatesTags: ['templates'],
      onQueryStarted: clearCache,
    }),
    getAllTemplates: builder.query<UserMDTemplate[], void>({
      query: () => '/auth/u/t',
      providesTags: (result) => providesList(result, 'templates'),
    }),
    createTemplate: builder.mutation<UserMDTemplate, BaseUserMDTemplate>({
      query: (body) => ({
        url: '/auth/u/t',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['templates'],
      onQueryStarted: clearCache,
    }),
    updateTemplate: builder.mutation<UserMDTemplate, UserMDTemplate>({
      query: (body) => ({
        url: `/auth/u/t/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['templates'],
      onQueryStarted: clearCache,
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useGetAllTemplatesQuery,
  useGetTemplateByIdQuery,
  useUpdateTemplateMutation,
} = templatesApi;
