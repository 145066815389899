import { SystemError, type UserSetting, type ErrorResponse } from '@soulhx/fs-common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '@soulhx/client-store';

export type BulkSettingUpdate = (updates: UserSetting[]) => void;

export interface SettingsResponse {
  settings: UserSetting[];
  settingsError?: ErrorResponse;
  settingsLoading: boolean;
  bulkUpdate: BulkSettingUpdate;
}

export const useBulkUserSettings = (): SettingsResponse => {
  const [settings, setSettings] = useState<UserSetting[]>([]);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [settingsError, setSettingsError] = useState<ErrorResponse | undefined>(undefined);
  const token = useSelector(getToken);

  useEffect(() => {
    if (token.length < 1) return;

    fetch('/api/auth/u/s', { headers: { Authorization: `bearer ${token}`, 'Content-Type': 'application/json' } })
      .then(async (response) => {
        if (!response.ok) {
          const responseErr = await response.json();
          throw responseErr;
        }

        const responseArray: UserSetting[] = await response.json();
        setSettings(responseArray);
        setSettingsLoading(false);
        setSettingsError(undefined);
      })
      .catch((err) => {
        if (err.errorCode && typeof err.errorCode === 'number') {
          setSettingsError(err);
        } else {
          setSettingsError(new SystemError('Error retrieving settings').serializeErrors());
        }
        setSettingsLoading(false);
      });
  }, [token]);

  const bulkUpdate = async (newSettings: UserSetting[]) => {
    if (token.length < 1 || newSettings.length < 1) return;

    setSettingsLoading(true);

    fetch('/api/auth/u/s/b', {
      method: 'PUT',
      body: JSON.stringify(newSettings),
      headers: { Authorization: `bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        if (!response.ok) {
          const respErr = await response.json();
          throw respErr;
        }

        const cache = await caches.open('settings');
        const keys = await cache.keys();
        for (const key of keys) {
          await cache.delete(key.url);
        }

        window.location.reload();
      })
      .catch((err) => {
        if (err.errorCode && typeof err.errorCode === 'number') {
          setSettingsError(err);
        } else {
          setSettingsError(new SystemError('Error getting settings').serializeErrors());
        }

        setSettingsLoading(false);
      });
  };

  return { settings, settingsLoading, settingsError, bulkUpdate };
};
