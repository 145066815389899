import React from 'react';
import { type TutorialData, type TutorialSection } from '../../../dm/tutorial.js';
import { Tutorial } from '../tutorials/Tutorial.js';

export const cheatSheetSection: TutorialSection = {
  i18nSection: 'cheatSheet',
  settingName: '',
  subsections: [
    { name: 'textFormatting', type: 'simple' },
    {
      name: 'smartFormatting',
      type: 'md',
      parts: [
        { i18nLocation: 'intro', partType: 'text' },
        { i18nLocation: 'example', partType: 'example' },
      ],
    },
    {
      name: 'bibleLinks',
      type: 'md',
      parts: [
        { i18nLocation: 'intro', partType: 'text' },
        { i18nLocation: 'example', partType: 'example' },
        { i18nLocation: 'outro', partType: 'text' },
      ],
    },
    {
      name: 'quotes',
      type: 'md',
      parts: [
        { i18nLocation: 'intro', partType: 'text' },
        { i18nLocation: 'example', partType: 'example' },
        { i18nLocation: 'keyboard', partType: 'text' },
      ],
    },
    {
      name: 'lists',
      type: 'md',
      parts: [
        { i18nLocation: 'intro', partType: 'text' },
        { i18nLocation: 'example', partType: 'example' },
      ],
    },
    {
      name: 'basicTables',
      type: 'md',
      parts: [
        { i18nLocation: 'intro', partType: 'text' },
        { i18nLocation: 'example', partType: 'example' },
      ],
    },
    { name: 'ignoreFormatting', type: 'md', parts: [{ i18nLocation: 'intro', partType: 'example' }] },
  ],
};

const tutorialData: TutorialData = {
  i18nNS: 'mdtut',
  sections: [cheatSheetSection],
};

export const CheatSheetTutorial = () => {
  return <Tutorial sectionCompleteCallback={() => {}} tutorialData={tutorialData} noSettings />;
};
