/**
 * Helper function to generate a hash on a string. Hashes should be
 * consistent over time (i.e. running this function over and over
 * returns the same hash every time) and unique across strings (i.e.
 * running this on different strings will never return the same hash).
 *
 * @param originalText The text from which a hash should be generated
 * @returns Hash string for the text
 */
export const generateSHA256Hash = async (originalText: string): Promise<string> => {
  const msgBuffer = new TextEncoder().encode(originalText);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};
