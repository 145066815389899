import React, { type HTMLAttributes, type MouseEventHandler } from 'react';
import { TooltipElement } from '../common/TooltipElement.js';
import { Button } from 'react-bootstrap';

export type ISHXButton = {
  /**
   * Bootstrap **variant** (e.g. primary, outline-secondary, ...)
   * to be used for the button
   */
  variant?: string;

  onClick?: MouseEventHandler<HTMLButtonElement>;

  disabled?: boolean;

  /**
   * Tooltip to show for the button. Overrides `itemType` setting
   * -- if `tooltip` is set, `itemType` is ignored.
   */
  tooltip?: string;

  /**
   * Type of item. Used for generating a tooltip for some types of
   * button, if a specific TT isn't provided.
   */
  itemType?: string;

  /**
   * Indicates if this is a form submit button (defaults false)
   */
  submit?: boolean;

  /**
   * Defaults to normal size
   */
  size?: 'sm' | 'lg' | undefined;
} & HTMLAttributes<HTMLButtonElement>;
/**
 * Renders a generic button with typical **SHX Styling**. May or may
 * not include a tooltip (via the `<TooltipElement>` component).
 */
export const SHXButton = ({
  variant,
  onClick,
  disabled,
  tooltip,
  children,
  submit = false,
  size = undefined,
  className,
  ...props
}: ISHXButton) => {
  const theAriaLabel = props['aria-label'] ? props['aria-label'] : typeof children === 'string' ? children : tooltip;

  if (tooltip) {
    return (
      <TooltipElement tooltip={tooltip}>
        <Button
          className={`border-0 text-uppercase fw-fold ${className || ''}`}
          aria-label={theAriaLabel}
          variant={variant}
          onClick={onClick}
          disabled={disabled}
          type={submit ? 'submit' : 'button'}
          size={size}
          {...props}
        >
          {children}
        </Button>
      </TooltipElement>
    );
  }

  return (
    <Button
      className={`border-0 text-uppercase fw-bold ${className || ''}`}
      aria-label={theAriaLabel}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      size={size}
      {...props}
    >
      {children}
    </Button>
  );
};
