import { createApi } from '@reduxjs/toolkit/query/react';
import {
  type BasePlanAttributes,
  type PlanAttributes,
  type PlanDay,
  type InstantiatedPlan,
  type BaseInstantiatedPlan,
  type InstantiatedPlanDay,
  type ReadingPlanDay,
  type CatchUpIPParams,
  type UpdatePlanName,
  type GetAllPlansSearch,
  getAvailPlanUrlFromParams,
} from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';

export interface DeleteResponseInterface {
  success: boolean;
}

export interface UpdateDayForPlanInterface {
  planId: string;
  day: PlanDay;
}

export interface IncrementPlanVersion {
  incrementType: 'major' | 'minor' | 'patch';
  plan: PlanAttributes;
}

export interface CatchupPlan {
  planId: string;
  params: CatchUpIPParams;
}

export interface UpdatePlanNameRequest {
  planInstanceId: string;
  details: UpdatePlanName;
}

const clearPlanData = async () => {
  const cache = await caches.open('plans');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

const clearIpData = async () => {
  const cache = await caches.open('instantiatedplans');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

const clearCacheData = async () => {
  await Promise.all([clearPlanData(), clearIpData()]);
};

export const plansApi = createApi({
  reducerPath: 'plans',
  baseQuery: customFetchBase,
  tagTypes: ['plans', 'instantiatedPlans'],
  endpoints: (builder) => ({
    getPlanById: builder.query<PlanAttributes, string>({
      query: (planId) => `/read/pl/p/${planId}`,
      providesTags: (result) =>
        result ? providesList([{ id: result!.planInstanceId }], 'plans', { returnList: false }) : [],
    }),
    deletePlan: builder.mutation<DeleteResponseInterface, string>({
      query: (planId) => ({ url: `/read/pl/p/${planId}`, method: 'DELETE' }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    savePlan: builder.mutation<PlanAttributes, PlanAttributes | BasePlanAttributes>({
      query: (newPlan) => ({
        url: '/read/pl/p/s',
        method: 'POST',
        body: newPlan,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    publishPlan: builder.mutation<PlanAttributes, PlanAttributes>({
      query: (newPlan) => ({
        url: '/read/pl/p/p',
        method: 'POST',
        body: newPlan,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    newPlanVersion: builder.mutation<PlanAttributes, IncrementPlanVersion>({
      query: (newPlan) => ({
        url: `/read/pl/p/p/newVersion/${newPlan.incrementType}`,
        method: 'POST',
        body: newPlan.plan,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    updatePlanName: builder.mutation<PlanAttributes, UpdatePlanNameRequest>({
      query: (request) => ({
        url: `/read/pl/p/updateName/${request.planInstanceId}`,
        method: 'PUT',
        body: request.details,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    getAvailablePlans: builder.query<PlanAttributes[], GetAllPlansSearch>({
      query: (params) => getAvailPlanUrlFromParams(params, false),
      providesTags: (result) =>
        result
          ? providesList(
              result.map((item) => ({ id: item.planInstanceId, ...item })),
              'plans'
            )
          : [],
    }),
    getReadingPlanDataForDay: builder.query<ReadingPlanDay[], string>({
      query: (dateString) => `/read/pl/ip/readingPlan/${dateString}`,
      providesTags: ['instantiatedPlans'],
    }),
    getSubscribedPlans: builder.query<InstantiatedPlan[], void>({
      query: () => '/read/pl/ip/subscribed',
      providesTags: (result) => providesList(result, 'instantiatedPlans'),
    }),
    newInstantiatedPlan: builder.mutation<InstantiatedPlan, BaseInstantiatedPlan>({
      query: (plan) => ({
        url: '/read/pl/ip',
        method: 'POST',
        body: plan,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    deleteInstantiatedPlan: builder.mutation<DeleteResponseInterface, string>({
      query: (ipId) => ({ url: `/read/pl/ip/${ipId}`, method: 'DELETE' }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    catchupInstantiatedPlan: builder.mutation<DeleteResponseInterface, CatchupPlan>({
      query: (details) => ({
        url: `/read/pl/ip/catchup/${details.planId}`,
        method: 'PUT',
        body: details.params,
      }),
      invalidatesTags: ['plans', 'instantiatedPlans'],
      onQueryStarted: clearCacheData,
    }),
    updateDayForInstantiatedPlan: builder.mutation<InstantiatedPlanDay, InstantiatedPlanDay>({
      query: (day) => ({
        url: `/read/pl/ip/d/${day.id}`,
        method: 'PUT',
        body: day,
      }),
      invalidatesTags: ['instantiatedPlans'],
      onQueryStarted: clearIpData,
    }),
  }),
});

export const {
  useGetPlanByIdQuery,
  useDeletePlanMutation,
  useSavePlanMutation,
  usePublishPlanMutation,
  useGetSubscribedPlansQuery,
  useNewInstantiatedPlanMutation,
  useDeleteInstantiatedPlanMutation,
  useUpdateDayForInstantiatedPlanMutation,
  useGetReadingPlanDataForDayQuery,
  useNewPlanVersionMutation,
  useCatchupInstantiatedPlanMutation,
  useGetAvailablePlansQuery,
  useLazyGetAvailablePlansQuery,
  useUpdatePlanNameMutation,
} = plansApi;
