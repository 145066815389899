import React, { type CSSProperties, type ReactElement, type ReactNode } from 'react';

export interface IMasonry {
  /**
   * Child elements to display
   */
  children: ReactNode;

  /**
   * Injected by ResponsiveMasonry; default 3
   */
  columnsCount?: number;

  /**
   * Margin surrounding each item (e.g. "10px", "1.5rem")
   *
   * essentially the CSS gap property
   *
   * Default "0"
   */
  gutter?: string;

  className?: string;

  style?: CSSProperties;
}
export const Masonry = ({ children, columnsCount = 3, gutter = '0', className, style }: IMasonry) => {
  const getColumns = () => {
    const columns = Array.from({ length: columnsCount }, () => {
      const column: ReactElement[] = [];

      return column;
    });
    let validIndex = 0;

    React.Children.forEach(children, (child) => {
      if (child && React.isValidElement(child)) {
        columns[validIndex % columnsCount].push(child);
        validIndex++;
      }
    });

    return columns;
  };

  const renderColumns = () => {
    return getColumns().map((column, i) => (
      <div
        key={`mas-col-${i}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'stretch',
          flex: 1,
          width: 0,
          gap: gutter,
        }}
      >
        {column.map((item) => item)}
      </div>
    ));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        width: '100%',
        gap: gutter,
        ...style,
      }}
      className={className}
    >
      {renderColumns()}
    </div>
  );
};
