import React, { type HTMLAttributes } from 'react';
import { getBGUrl } from '../../../hooks/md/extensions/bibleLink.js';
import { getFormattedRef } from '@soulhx/fs-common';

type IBibleLink = {
  passage: string;
  includeVerses?: boolean;
  context?: string;
  version?: string;
  hideVersion?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLAnchorElement>;
export const BibleLink = ({
  passage,
  children,
  context,
  version,
  includeVerses = true,
  hideVersion = false,
  disabled = false,
  ...props
}: IBibleLink) => {
  if (disabled) return null;

  let title = passage;
  if (version && !hideVersion) title += ` (${version})`;

  return (
    <a
      title={title}
      href={getBGUrl(passage, { context, includeVerses, version })}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children || getFormattedRef(passage, { includeVerses, context })}✞
    </a>
  );
};
