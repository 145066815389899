import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from './helpers/customFetchBase.js';
import {
  type ActionStats,
  type StatsDurations,
  type DailyActionStats,
  type VisualisationProps,
} from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';

export interface DeleteResponse {
  success: boolean;
}

const deleteGraphCache = async () => {
  const cache = await caches.open('graphs');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

export const statsApi = createApi({
  reducerPath: 'stats',
  baseQuery: customFetchBase,
  tagTypes: ['allstats', 'statsbyday', 'graphs'],
  endpoints: (builder) => ({
    getAllStats: builder.query<ActionStats, StatsDurations>({
      query: (dur) => `/do/s/all?tf=${dur}`,
      providesTags: ['allstats'],
    }),
    getStatsByDay: builder.query<DailyActionStats[], StatsDurations>({
      query: (dur) => `/do/s/byDay?tf=${dur}`,
      providesTags: ['statsbyday'],
    }),
    getGraphs: builder.query<VisualisationProps[], void>({
      query: () => '/do/g',
      providesTags: ['graphs'],
    }),
    getGraphById: builder.query<VisualisationProps, string>({
      query: (id: string) => `/do/g/${id}`,
      providesTags: (result) => (result ? providesList([result!], 'graphs', { returnList: false }) : []),
    }),
    saveNewGraph: builder.mutation<VisualisationProps, VisualisationProps>({
      query: (props) => ({
        url: '/do/g',
        method: 'POST',
        body: props,
      }),
      invalidatesTags: ['graphs'],
      onQueryStarted: deleteGraphCache,
    }),
    updateGraph: builder.mutation<VisualisationProps, VisualisationProps>({
      query: (props) => ({
        url: `/do/g/${props.id}`,
        method: 'PUT',
        body: props,
      }),
      invalidatesTags: ['graphs'],
      onQueryStarted: deleteGraphCache,
    }),
    deleteGraph: builder.mutation<DeleteResponse, string>({
      query: (id) => ({
        url: `/do/g/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['graphs'],
      onQueryStarted: deleteGraphCache,
    }),
  }),
});

export const {
  useGetAllStatsQuery,
  useGetStatsByDayQuery,
  useGetGraphsQuery,
  useSaveNewGraphMutation,
  useUpdateGraphMutation,
  useGetGraphByIdQuery,
  useDeleteGraphMutation,
} = statsApi;
