import { type GetAllPlansSearch } from '../dm/Plan.js';

export const getAvailPlanUrlFromParams = (searchParams: GetAllPlansSearch, full = true): string => {
  const urlParts: string[] = [];

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push('/read/pl/p');

  if (searchParams.page) {
    urlParts.push('?p=', searchParams.page.toString());
  }

  return urlParts.join('');
};
