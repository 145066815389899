/* eslint-disable @typescript-eslint/no-explicit-any */
import { type MigrationManifest, createMigrate } from 'redux-persist';

/*
 * Each migration step will take one version as input and return the
 * next version as output. (The key `2` means that it is the step which
 * migrates from V1 to V2.)
 *
 * Due to strangeness with Typescript and the deep, deep nature of
 * the state object, just giving up altogether on types, using `any`,
 * and setting the new properties as needed.
 */
const persistMigrations: MigrationManifest = {
  2: (state: any) => {
    state.persistedUI.showHoverText = false;

    return state;
  },
};

export const persistMigrate = createMigrate(persistMigrations);

export const persistVersion = 2;
