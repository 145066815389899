import { type InstantiatedPlan, type ReadingPlanDay, ReadingPlanStatus, type Verse } from '@soulhx/fs-common';
import { DateTime } from 'luxon';

export const getDateRangeForReadingPlans = (plans: ReadingPlanDay[] | undefined): [DateTime, DateTime] => {
  if (!plans || plans.length < 1) {
    return [DateTime.now(), DateTime.now()];
  }

  const sequenceNums = plans.map((item) => item.sequenceNum);
  const minDate = DateTime.now().minus({ days: Math.min(...sequenceNums) - 1 });

  const remainingDays = plans.map((item) => item.totalDays - item.sequenceNum);
  const maxDate = DateTime.now().plus({ days: Math.max(...remainingDays) - 1 });

  return [minDate, maxDate];
};

export const getDateRangeForPlans = (plans: InstantiatedPlan[] | undefined): [DateTime, DateTime] => {
  if (!plans) return [DateTime.now(), DateTime.now()];

  const minDates: DateTime[] = plans!
    .filter((plan) => plan.days)
    .map((plan) => DateTime.fromISO(plan.days![0].scheduledDate));
  const maxDates: DateTime[] = plans!
    .filter((plan) => plan.days)
    .map((plan) => DateTime.fromISO(plan.days![plan.days!.length - 1].scheduledDate));

  return [DateTime.min(...minDates), DateTime.max(...maxDates)];
};

export const getOtNtPassageStatsFromVerses = (verses: Verse[]): [number, number] => {
  let otVerses = 0;
  let ntVerses = 0;

  for (let i = 0; i < verses.length; i++) {
    if (verses[i].newTestament) {
      ntVerses++;
    } else {
      otVerses++;
    }
  }

  return [otVerses, ntVerses];
};

/**
 * Calculates the variant (and button variant) to use for Bootstrap
 * colours for this reading plan item.
 *
 * @param plan Current plan day item
 * @returns a variant and button variant to use for Bootstrap
 */
export const getReadingPlanClass = (plan: ReadingPlanDay): string => {
  if (plan.dayCompleted) return 'rpi-completed';

  if (plan.readingPlanStatus === ReadingPlanStatus.red) return 'rpi-red';

  if (plan.readingPlanStatus === ReadingPlanStatus.yellow) return 'rpi-yellow';

  return 'rpi-base';
};
