import { StatsDurations, type UserSetting } from '@soulhx/fs-common';

/**
 * Interface for Setting data as stored in the database (where all
 * values are strings and Booleans are stored as the string `"true"`
 * or `"false"`).
 */
export interface DbItem {
  settingId: string;
  settingValue: string;
}

/**
 * Takes a string and converts it to a Boolean value, for use in working
 * with User Settings. Very simply looks for the string `"false"` to return
 * `false`, otherwise returns `true`.
 *
 * @param input A string representing the purported Boolean value
 * @returns Proper `true` or `false` response
 */
const getBoolFromString = (input: string): boolean => {
  if (input === 'false') {
    return false;
  }

  return true;
};

const allSettings = [
  'prayerShowAllItems',
  'prayerSort',
  'prayerShowSettings',
  'prayerShowHashtags',
  'readDefaultVersion',
  'readPassageSortOrder',
  'readNotesSortOrder',
  'readShowSettings',
  'readAutosaveNotes',
  'readShowIframe',
  'readShowHashtags',
  'planShowSettings',
  'adminUserlistLimit',
  'adminUserlistSortby',
  'adminUserlistSortAsc',
  'adminShowSizeIndicator',
  'generalShowTemplateSetting',
  'generalShowHelpTextResetSetting',
  'generalShowResetSettings',
  'generalShowActionBtns',
  'doCalendarActions',
  'doShowSettings',
  'doChartDataDuration',
  'doChartOrder',
  'homeShowSettings',
  'wizardMain',
  'wizardActionItems',
  'wizardPrayer',
  'wizardStudy',
  'wizardReadingPlans',
  'generalWizardDelayDate',
  'betaUseNewEditor',
];
const stringBasedSettings = [
  'adminUserlistSortby',
  'adminUserlistLimit',
  'prayerSort',
  'readNotesSortOrder',
  'readDefaultVersion',
  'readPassageSortOrder',
  'doCalendarActions',
  'doChartDataDuration',
  'doChartOrder',
  'generalWizardDelayDate',
];

/**
 * Takes a {@link DbItem} object representing a User Setting
 * and returns a {@link UserSetting} object with its
 * `settingStringValue` or `settingBoolValue` attribute
 * properly set. Doesn't bother checking setting validity
 * (i.e. whether it's a "valid" setting or not) if the name
 * starts with `helpText`.
 *
 * @param item The item to be parsed
 * @returns `UserSetting` object with String or Boolean value, or `undefined` if invalid `settingId` passed
 */
const getItem = (item: DbItem): UserSetting | undefined => {
  if (item.settingId.startsWith('helpText.')) {
    return { settingId: item.settingId, settingBoolValue: getBoolFromString(item.settingValue) };
  }

  if (!allSettings.includes(item.settingId)) return undefined;

  const isStringSetting = stringBasedSettings.includes(item.settingId);

  return isStringSetting
    ? { settingId: item.settingId, settingStringValue: item.settingValue }
    : { settingId: item.settingId, settingBoolValue: getBoolFromString(item.settingValue) };
};

/**
 * Takes an array of DB records and converts it to an array of {@link UserSetting}
 * objects. "Invalid" objects are stripped from the array.
 *
 * @param items Array of DB records
 * @returns Array of populated User Setting objects
 */
export const getUserSettingsFromDbArray = (items: DbItem[]): UserSetting[] => {
  const settings: UserSetting[] = [];

  for (const item of items) {
    const newSetting = getItem(item);
    if (newSetting) {
      settings.push(newSetting);
    }
  }

  return settings;
};

/**
 * List of defaults for all of the settings (other than `helpText`
 * settings), as a Map.
 *
 * Could have been implemented in other ways, but this implementation
 * allows for test cases to be able to to `.length` and for
 * {@link getDefaultForSetting} to be used to get defaults in a
 * performant manner.
 */
export const rawDefaultDbItems = new Map<string, string>([
  ['prayerShowAllItems', 'true'],
  ['prayerSort', 'date-desc'],
  ['prayerShowSettings', 'true'],
  ['prayerShowHashtags', 'true'],
  ['readDefaultVersion', 'ESV'],
  ['readPassageSortOrder', 'date-desc'],
  ['readNotesSortOrder', 'date-desc'],
  ['readShowSettings', 'true'],
  ['readAutosaveNotes', 'true'],
  ['readShowIframe', 'true'],
  ['readShowHashtags', 'true'],
  ['planShowSettings', 'true'],
  ['adminUserlistLimit', '50'],
  ['adminUserlistSortby', 'username'],
  ['adminUserlistSortAsc', 'true'],
  ['adminShowSizeIndicator', 'false'],
  ['generalShowTemplateSetting', 'true'],
  ['generalShowHelpTextResetSetting', 'true'],
  ['generalShowResetSettings', 'true'],
  ['generalShowActionBtns', 'true'],
  ['doCalendarActions', ''],
  ['doShowSettings', 'true'],
  ['doChartDataDuration', StatsDurations.AllTime],
  ['doChartOrder', ''],
  ['homeShowSettings', 'true'],
  ['wizardMain', 'false'],
  ['wizardActionItems', 'false'],
  ['wizardPrayer', 'false'],
  ['wizardStudy', 'false'],
  ['wizardReadingPlans', 'false'],
  ['generalWizardDelayDate', ''],
  ['betaUseNewEditor', 'false'],
]);

/**
 * Gets a list of all possible User Settings (other than `helpText`)
 * with their default values populated.
 *
 * @returns Array of setting objects with all of the defaults populated
 */
export const getDefaultSettings = (): UserSetting[] => {
  const defaults: DbItem[] = Array.from(rawDefaultDbItems, (key) => ({ settingId: key[0], settingValue: key[1] }));

  return getUserSettingsFromDbArray(defaults);
};

/**
 * Returns a list of all possible settings in the system for a
 * given user. If the user has a saved configuration for that
 * setting it is used, otherwise the default is used. If the
 * user has saved `helpText` settings they will also be returned
 * as-is, though the system doesn't have a list of *all* `helpText`
 * settings, so it's up to the calling application to properly
 * default those.
 *
 * @param userSettings Array of settings saved in the DB for the user (if any)
 * @returns  Array of setting objects for *all* settings
 */
export const getFullSettingListForUser = (userSettings: DbItem[]): UserSetting[] => {
  const userSettingsConverted = getUserSettingsFromDbArray(userSettings);
  const defaults = getDefaultSettings().filter(
    (defaultItem) => userSettingsConverted.findIndex((us) => us.settingId === defaultItem.settingId) < 0
  );

  return userSettingsConverted.concat(defaults);
};

/**
 * Helper function to get a particular setting value (from an array of
 * {@link UserSetting} objects), as a **string**.
 *
 * **Note**: This function would still work if the setting is a Boolean,
 * with the value simply converted to a string (`"true"` or `"false"`).
 *
 * @example
 * settingList = [
 *   { settingId: 'prayerSort', settingStringValue: 'NEWVALUE },
 *   { settingId: 'prayerShowAllItems', settingBoolValue: false}
 * ];
 *
 * //returns 'NEWVALUE'
 * getStringSettingValue('prayerSort', settingList);
 *
 * //returns the string 'false'
 * getStringSettingValue('prayerShowAllItems', settingList);
 *
 * @param settingName Name of the setting to be retrieved
 * @param settingList Array of settings for the user
 * @returns The value of the setting, as a string
 */
export const getStringSettingValue = (settingName: string, settingList: UserSetting[]): string => {
  const index = settingList.findIndex((i) => i.settingId === settingName);
  if (index >= 0) {
    const s = settingList[index];

    if (s.settingStringValue) {
      return s.settingStringValue;
    }

    if (s.settingBoolValue !== undefined) {
      return s.settingBoolValue.toString();
    }
  }

  if (settingName.startsWith('helpText')) return 'true';

  return '';
};

/**
 * Helper function to get a particular setting value (from an array of
 * {@link UserSetting} objects), as a **boolean**.
 *
 * **Note**: This function would still "work" if the setting is a string,
 * except that it will always return `false`.
 *
 * @example
 * settingList = [
 *   { settingId: 'prayerSort', settingStringValue: 'NEWVALUE },
 *   { settingId: 'prayerShowAllItems', settingBoolValue: false}
 * ];
 *
 * //returns false
 * getBoolSettingValue('prayerSort', settingList);
 *
 * //returns the false
 * getBoolSettingValue('prayerShowAllItems', settingList);
 *
 * @param settingName Name of the setting to be retrieved
 * @param settingList Array of settings for the user
 * @returns The value of the setting, as a Boolean
 */
export const getBoolSettingValue = (settingName: string, settingList: UserSetting[]): boolean => {
  const index = settingList.findIndex((i) => i.settingId === settingName);
  if (index >= 0) {
    const s = settingList[index];

    if (s.settingBoolValue !== undefined) {
      return s.settingBoolValue;
    }
  }

  if (settingName.startsWith('helpText')) return true;

  return false;
};

/**
 * Indicates if the setting name being passed is a valid one.
 *
 * Names starting with `helpText` will always be true; the app
 * doesn't enforce these, since they can be created/decommissioned
 * on a regular basis.
 *
 * @example
 * // returns true because it's a valid setting
 * isValidSetting('prayerSort');
 *
 * // returns true because `helpText` names always do
 * isValidSetting('helpTextSomeSettingName');
 *
 * // returns false because it's not a valid name
 * // (and not helpText)
 * isValidSetting('someRandomSettingName')
 *
 * @param settingName Name of the setting to be tested
 * @returns True if it's a valid setting, false otherwise
 */
export const isValidSetting = (settingName: string): boolean => {
  if (settingName.startsWith('helpText')) return true;

  return rawDefaultDbItems.has(settingName);
};

/**
 * Returns the default value for any setting
 *
 * @example
 * // returns Boolean true
 * getDefaultForSetting('prayerShowAllItems')
 *
 * // returns the string 'ESV'
 * getDefaultForSetting('readDefaultVersion')
 *
 * // returns the Boolean true
 * getDefaultForSetting('helpTextSomeSettingName');
 *
 * // returns undefined
 * getDefaultForSetting('someUnheardOfSetting')
 *
 * @param settingName Name of the setting
 * @returns String or boolean, or undefined if the setting doesn't exist
 */
export const getDefaultForSetting = (settingName: string): string | boolean | undefined => {
  if (settingName.startsWith('helpText')) return true;

  if (!rawDefaultDbItems.has(settingName)) return undefined;

  if (stringBasedSettings.includes(settingName)) return rawDefaultDbItems.get(settingName);

  return rawDefaultDbItems.get(settingName) === 'true';
};

/**
 * Returns the default string value of a setting (even if the setting is
 * normally a bool). Returns empty string for invalid setting.
 *
 * @example
 * // returns the string 'true'
 * getDefaultForSetting('prayerShowAllItems')
 *
 * // returns the string 'ESV'
 * getDefaultForSetting('readDefaultVersion')
 *
 * // returns the string 'true'
 * getDefaultForSetting('helpTextSomeSettingName');
 *
 * // returns an empty string
 * getDefaultForSetting('someUnheardOfSetting')
 *
 * @param settingName Name of the setting
 * @returns String value
 */
export const getDefStringForSetting = (settingName: string): string => {
  if (settingName.startsWith('helpText')) return 'true';

  return rawDefaultDbItems.get(settingName) || '';
};

/**
 * Returns the default boolean for a setting; just returns `true`
 * if the string value is `'true'` and `false` otherwise.
 *
 * @example
 * // returns Boolean true
 * getDefaultForSetting('prayerShowAllItems')
 *
 * // returns the Boolean false
 * getDefaultForSetting('readDefaultVersion')
 *
 * // returns the Boolean true
 * getDefaultForSetting('helpTextSomeSettingName');
 *
 * // returns the Boolean false
 * getDefaultForSetting('someUnheardOfSetting')
 *
 * @param settingName Name of the setting
 * @returns Boolean value
 */
export const getDefBoolForSetting = (settingName: string): boolean => {
  if (settingName.startsWith('helpText')) return true;

  if (!rawDefaultDbItems.has(settingName)) return false;

  return rawDefaultDbItems.get(settingName) === 'true';
};
