import { type ActionType } from '@soulhx/fs-common';
import { useGetActionTypesQuery, useGetSpecialTypeIDsQuery } from '../services/ActionsService.js';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

export interface GetActionTypesResponse {
  /**
   * List of action types, with i18n-translated display names
   */
  actionTypes: ActionType[] | undefined;

  /**
   * Error returned from the API(s), if any
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  actionTypeError: any;

  /**
   * Indicator that the data is still loading
   */
  actionTypesLoading: boolean;
}

interface GetATOptions {
  /**
   * Whether to use **long** versions of the `displayName` (true) or
   * **short** (false); defaults to true/long
   */
  longNames?: boolean;

  /**
   * Whether to filter the results to bring back only available
   * types (true/default) or all results (false)
   */
  availableOnly?: boolean;

  /**
   * Whether the Scripture action type should be included (defaults
   * to false). This is a special "action type" (read-only), so
   * only useful in some circumstances.
   */
  includeScripture?: boolean;
}

/**
 * Hook to act as a wrapper around `useGetActionTypesQuery()` to
 * handle i18n and (optional) filtering.
 *
 * @returns Same results as if `useGetActionTypesQuery()` were called, but with i18n displayNames and (optionally) filtered to only show active
 */
export const useGetActionTypes = ({
  longNames = true,
  availableOnly = true,
  includeScripture = false,
}: GetATOptions = {}): GetActionTypesResponse => {
  const { data, error, isLoading } = useGetActionTypesQuery();
  const { data: specialIds, error: specialTypesError, isLoading: specialTypesLoading } = useGetSpecialTypeIDsQuery();
  const { t } = useTranslation();

  const getDisplayName = useCallback(
    (type: ActionType): string => {
      if (!specialIds) return type.displayName;

      const i18nPrefix = longNames ? 'do:actionTypes.long.' : 'do:actionTypes.short.';

      if (type.id === specialIds.book) {
        return t(i18nPrefix + 'book');
      }
      if (type.id === specialIds.church) {
        return t(i18nPrefix + 'church');
      }
      if (type.id === specialIds.journal) {
        return t(i18nPrefix + 'journal');
      }
      if (type.id === specialIds.longNT) {
        return t(i18nPrefix + 'longNT');
      }
      if (type.id === specialIds.longOT) {
        return t(i18nPrefix + 'longOT');
      }
      if (type.id === specialIds.podcast) {
        return t(i18nPrefix + 'podcast');
      }
      if (type.id === specialIds.prayer) {
        return t(i18nPrefix + 'prayer');
      }
      if (type.id === specialIds.scripture) {
        return t(i18nPrefix + 'scripture');
      }
      if (type.id === specialIds.shortNT) {
        return t(i18nPrefix + 'shortNT');
      }
      if (type.id === specialIds.shortOT) {
        return t(i18nPrefix + 'shortOT');
      }

      return type.displayName;
    },
    [specialIds, t, longNames]
  );

  const responseArray: ActionType[] = useMemo(() => {
    if (!data || !specialIds) return [];

    let theArray = data.map((at) => ({
      id: at.id,
      displayName: getDisplayName(at),
      isAvailable: at.isAvailable,
      isDefault: at.isDefault,
    }));

    if (includeScripture) {
      const index = theArray.findIndex((at) => at.id === specialIds.scripture);
      if (index < 0) {
        theArray = theArray.concat({
          id: specialIds.scripture,
          displayName: longNames ? t('do:actionTypes.long.scripture') : t('do:actionTypes.short.scripture'),
          isAvailable: true,
          isDefault: true,
        });
      } else {
        theArray[index].isAvailable = true;
      }
    }
    if (!includeScripture) {
      theArray = theArray.filter((at) => at.id !== specialIds.scripture);
    }

    if (availableOnly) {
      return theArray.filter((at) => at.isAvailable);
    }

    return theArray;
  }, [data, specialIds, t, longNames, availableOnly, includeScripture, getDisplayName]);

  if (isLoading || specialTypesLoading) {
    return { actionTypes: data, actionTypeError: error, actionTypesLoading: true };
  }
  if (error) {
    return { actionTypes: undefined, actionTypeError: error, actionTypesLoading: false };
  }
  if (specialTypesError) {
    return { actionTypes: undefined, actionTypeError: specialTypesError, actionTypesLoading: false };
  }

  return { actionTypes: responseArray, actionTypeError: error, actionTypesLoading: isLoading };
};
