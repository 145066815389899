import React, { useCallback, useState } from 'react';
import { type TutorialSubSectionContent } from '../../../dm/tutorial.js';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NextButton } from './helpers/NextButton.js';
import { Subsection } from './Subsection.js';

interface SectionUI {
  content: TutorialSubSectionContent;
  completed: boolean;
}

interface ITutorialSection {
  markComplete(): void;
  i18NS: string;
  i18nLocation: string;
  subSections: TutorialSubSectionContent[];
  noSettings?: boolean;
}
export const TutorialSection = ({
  markComplete,
  subSections,
  i18nLocation,
  i18NS,
  noSettings = false,
}: ITutorialSection) => {
  const { t } = useTranslation(['translation', i18NS]);
  const [uiSections, setUiSections] = useState<SectionUI[]>(subSections.map((s) => ({ content: s, completed: false })));
  const [currentPage, setCurrentPage] = useState(subSections[0].name);

  const setSectionComplete = useCallback(
    (sectionName: string) => {
      const newValue = uiSections.slice();
      const index = newValue.findIndex((s) => s.content.name === sectionName);
      newValue[index].completed = true;
      setUiSections(newValue);

      if (index < newValue.length - 1) {
        setCurrentPage(newValue[index + 1].content.name);
      }
    },
    [uiSections, setUiSections]
  );

  return (
    <Accordion activeKey={currentPage} onSelect={(e) => setCurrentPage(e as string)}>
      {uiSections.map((section, index) => {
        const display = index === 0 || noSettings ? true : uiSections[index - 1].completed;
        const sectionName = section.content.name;
        const lastItem = index === uiSections.length - 1;

        const completeFunc = lastItem ? () => markComplete() : () => setSectionComplete(sectionName);

        const nextButton = noSettings ? null : (
          <NextButton setComplete={completeFunc} lastOne={lastItem}>
            {lastItem
              ? t('translation:tutorials.completeSectionBtn')
              : t('translation:tutorials.completeSubSectionBtn', {
                  nextText: t(`${i18NS}:${i18nLocation}.${uiSections[index + 1].content.name}.title`),
                })}
          </NextButton>
        );

        return (
          <Accordion.Item
            key={`subsection-${sectionName}`}
            eventKey={sectionName}
            className={display ? 'd-block' : 'd-none'}
          >
            <Accordion.Header>{t(`${i18NS}:${i18nLocation}.${sectionName}.title`)}</Accordion.Header>

            <Accordion.Body>
              <Subsection
                i18nNS={i18NS}
                i18nSection={i18nLocation}
                nextButton={nextButton}
                subSection={subSections[index]}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};
