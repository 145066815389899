import { createApi } from '@reduxjs/toolkit/query/react';
import {
  type PrayerItem,
  type BasePrayerItem,
  type PrayerListItem,
  type BasePrayerListItem,
  type PaginatedPrayersRequest,
  type Hashtag,
  type SetHtRequest,
  type AddHtRequest,
  getPaginatedPrayerUrlFromParams,
  type PaginatedPliRequest,
  getPaginatedPliFromParams,
} from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';

export interface PrayerSubmissionObject {
  item: BasePrayerItem;
  token: string;
}

export interface MinimalGetInterface {
  id: string;
  token: string;
}

export interface DeleteResponse {
  success: boolean;
}

export interface ItemsForListParams {
  listId: string;
  params: PaginatedPliRequest;
}

export interface AttachPrayerToListParams {
  toListId: string;
  item: PrayerItem;
}

export interface SetTagsForPrayerParams {
  prayerId: string;
  requestBody: SetHtRequest;
}

export interface AddTagToPrayerParams {
  prayerId: string;
  requestBody: AddHtRequest;
}

export interface RemoveTagFromPrayerParams {
  prayerId: string;
  tag: string;
}

export const deletePrayerCache = async () => {
  const cache = await caches.open('prayer');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

export const deleteCacheForLI = async (item: PrayerListItem) => {
  const cache = await caches.open('prayer');
  const keys = await cache.keys();

  for (const k of keys) {
    if (k.url.includes(`/api/pray/${item.prayerListId}`)) {
      await cache.delete(k.url);
    }
  }
};

export const prayerApi = createApi({
  reducerPath: 'prayer',
  baseQuery: customFetchBase,
  tagTypes: ['prayer', 'prayerListItems'],
  endpoints: (builder) => ({
    newPrayerItem: builder.mutation<PrayerItem, BasePrayerItem>({
      query: (body) => ({
        url: '/pray/',
        body: body,
        method: 'POST',
      }),
      invalidatesTags: ['prayer'],
      onQueryStarted: deletePrayerCache,
    }),
    getAllPrayerItems: builder.query<PrayerItem[], PaginatedPrayersRequest>({
      query: (params) => getPaginatedPrayerUrlFromParams(params, false),
      providesTags: (result) => providesList(result, 'prayer'),
    }),
    getPrayerItemById: builder.query<PrayerItem, string>({
      query: (param) => `/pray/${param}`,
    }),
    deletePrayerItem: builder.mutation<DeleteResponse, string>({
      query: (id) => ({ url: `/pray/${id}`, method: 'DELETE' }),
      invalidatesTags: ['prayer'],
      onQueryStarted: deletePrayerCache,
    }),
    deletePrayerListItem: builder.mutation<DeleteResponse, string>({
      query: (id) => ({ url: `/pray/pli/${id}`, method: 'DELETE' }),
      invalidatesTags: ['prayerListItems'],
      onQueryStarted: deletePrayerCache,
    }),
    updatePrayerItem: builder.mutation<PrayerItem, PrayerItem>({
      query: (item) => ({
        url: `/pray/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.id, type: 'prayer' }];
      },
      onQueryStarted: deletePrayerCache,
    }),
    updatePrayerComplete: builder.mutation<PrayerItem, PrayerItem>({
      query: (item) => ({
        url: `/pray/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [
          { id: params.id, type: 'prayer' },
          { id: 'LIST', type: 'prayer' },
        ];
      },
      onQueryStarted: deletePrayerCache,
    }),
    getItemsForPrayerList: builder.query<PrayerListItem[], ItemsForListParams>({
      query: (params) => getPaginatedPliFromParams(params.listId, params.params, false),
      providesTags: (result) => providesList(result, 'prayerListItems'),
    }),
    newItemForPrayerList: builder.mutation<PrayerListItem, BasePrayerListItem>({
      query: (item) => ({
        url: `/pray/${item.prayerListId}/listItems`,
        method: 'POST',
        body: item,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [
          { id: params.prayerListId, type: 'prayer' },
          { id: result.id, type: 'prayerListItems' },
          { id: 'LIST', type: 'prayerListItems' },
        ];
      },
      onQueryStarted: deleteCacheForLI,
    }),
    updateItemForPrayerList: builder.mutation<PrayerListItem, PrayerListItem>({
      query: (item) => ({
        url: `/pray/pli/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [
          { id: result.id, type: 'prayerListItems' },
          { id: params.prayerListId, type: 'prayer' },
        ];
      },
      onQueryStarted: deleteCacheForLI,
    }),
    updateItemForPrayerListComplete: builder.mutation<PrayerListItem, PrayerListItem>({
      query: (item) => ({
        url: `/pray/pli/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [
          { id: result.id, type: 'prayerListItems' },
          { id: 'LIST', type: 'prayerListItems' },
          { id: params.prayerListId, type: 'prayer' },
        ];
      },
      onQueryStarted: deleteCacheForLI,
    }),
    attachPrayerItemToList: builder.mutation<PrayerListItem, AttachPrayerToListParams>({
      query: (inputs) => ({
        url: `/pray/attach/${inputs.toListId}`,
        method: 'POST',
        body: inputs.item,
      }),
      invalidatesTags: ['prayer', 'prayerListItems'],
      onQueryStarted: deletePrayerCache,
    }),
    detatchPrayerItemFromList: builder.mutation<PrayerItem, PrayerListItem>({
      query: (item) => ({
        url: `/pray/plidetatch/${item.id}`,
        method: 'POST',
        body: item,
      }),
      invalidatesTags: ['prayer', 'prayerListItems'],
      onQueryStarted: deletePrayerCache,
    }),
    getUserHashtags: builder.query<string[], string | undefined>({
      query: (search) => `/pray/ht${search ? '?q=' + search : ''}`,
    }),
    setPrayerHashtags: builder.mutation<Hashtag[], SetTagsForPrayerParams>({
      query: (params) => ({
        url: `/pray/${params.prayerId}/setTags`,
        method: 'POST',
        body: params.requestBody,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.prayerId, type: 'prayer' }];
      },
      onQueryStarted: deletePrayerCache,
    }),
    addHtToPrayer: builder.mutation<Hashtag, AddTagToPrayerParams>({
      query: (params) => ({
        url: `/pray/${params.prayerId}/addHt`,
        method: 'POST',
        body: params.requestBody,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.prayerId, type: 'prayer' }];
      },
      onQueryStarted: deletePrayerCache,
    }),
    removeHtFromPrayer: builder.mutation<DeleteResponse, RemoveTagFromPrayerParams>({
      query: (params) => ({
        url: `/pray/${params.prayerId}/removeHt/${params.tag}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.prayerId, type: 'prayer' }];
      },
      onQueryStarted: deletePrayerCache,
    }),
  }),
});

export const {
  useNewPrayerItemMutation,
  useGetAllPrayerItemsQuery,
  useGetPrayerItemByIdQuery,
  useLazyGetAllPrayerItemsQuery,
  useDeletePrayerItemMutation,
  useUpdatePrayerItemMutation,
  useGetItemsForPrayerListQuery,
  useLazyGetItemsForPrayerListQuery,
  useNewItemForPrayerListMutation,
  useUpdateItemForPrayerListMutation,
  useDeletePrayerListItemMutation,
  useAttachPrayerItemToListMutation,
  useDetatchPrayerItemFromListMutation,
  useSetPrayerHashtagsMutation,
  useAddHtToPrayerMutation,
  useRemoveHtFromPrayerMutation,
  useGetUserHashtagsQuery,
  useUpdateItemForPrayerListCompleteMutation,
  useUpdatePrayerCompleteMutation,
} = prayerApi;
