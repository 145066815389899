/**
 * Helper function to replace lines starting with double spaces with
 * properly formatted `>` delimiters. That is:
 *
 * ```
 * > text
 * >  text
 * >    text
 * ```
 *
 * would be replaced with
 *
 * ```
 * > text
 * > > text
 * > > > text
 * ```
 *
 * Had to be designed as a **recursive** function, since the JS
 * `replaceAll` function doesn't work recursively. This feels like
 * a hack, why *wouldn't* `replaceAll` work? But for now, it is
 * what it is.
 *
 * @param origString String to be searched
 * @returns Modified string with double spaces replaced accordingly
 */
const replaceDoubleSpacesAtBeg = (origString: string): string => {
  const newString = origString.replaceAll(/> {2}( )?/gm, '> > ');

  if (newString === origString) return origString;

  return replaceDoubleSpacesAtBeg(newString);
};

const capitalizedWordsRE = /\b([\p{Lu}\p{Lt}]{2,})\b/gu;

/**
 * Takes in "raw" text from the Bible and formats it with appropriate
 * markdown for use in SoulHX:
 *
 * - Indents text with `> ` notations and handles newlines
 * - Looks for numbers that could be verses and superscripts them
 * - Looks for words that are all in capitals (e.g. "LORD") and formats them
 * - inserts the citation at the beginning (if supplied)
 *
 * @param originalText Unformatted text
 * @param citation (optional) Citation to include
 * @returns Text formatted as markdown
 */
export const formatBiblePassageInMD = (originalText: string, citation?: string): string => {
  // handle newlines and indentation
  let newText = '> ';
  if (citation !== undefined && citation.length > 0) {
    newText += `((${citation})) `;
  }
  newText += originalText.replaceAll('\n', '\n> ');
  newText = replaceDoubleSpacesAtBeg(newText);

  // handle numbers that look like chapter/verse
  newText = newText.replaceAll(/^(\d+)\s/g, ' ^$1^ ');
  newText = newText.replaceAll(/\s(\d+)\s/g, ' ^$1^ ');

  // all caps words
  newText = newText.replaceAll(capitalizedWordsRE, `^^^$1^^^`);

  return newText;
};
