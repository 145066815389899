import { createApi } from '@reduxjs/toolkit/query/react';
import { type Verse } from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';
import { escapeForURL } from '@soulhx/fs-common';

export const verseApi = createApi({
  reducerPath: 'verses',
  baseQuery: customFetchBase,
  tagTypes: ['verses'],
  endpoints: (builder) => ({
    getVersesForOsis: builder.query<Verse[], string>({
      query: (osisString) => `/read/v/forOsis?osis=${escapeForURL(osisString)}`,
      providesTags: (result) =>
        result
          ? providesList(
              result!.map((item) => ({ id: item.osis })),
              'verses'
            )
          : [],
    }),
  }),
});

export const { useGetVersesForOsisQuery, useLazyGetVersesForOsisQuery } = verseApi;
