import React from 'react';
import { Funnel } from 'react-bootstrap-icons';
import { type IconProps, type IIconProps } from '../dm/icons.js';
import { ChurchIcon } from './svg/ChurchIcon.js';
import { JournalIcon } from './svg/JournalIcon.js';
import { PrayIcon } from './svg/PrayIcon.js';
import { NTIcon } from './svg/NTIcon.js';
import { BibleIcon } from './svg/BibleIcon.js';
import { BibleOpenIcon } from './svg/BibleOpenIcon.js';
import { ChristianBookIcon } from './svg/ChristianBookIcon.js';
import { PodcastIcon } from './svg/PodcastIcon.js';
import { OTIcon } from './svg/OTIcon.js';
import { Logo } from './svg/Logo.js';
import { DoIcon } from './svg/DoIcon.js';
import { ReadingListIcon } from './svg/ReadingListIcon.js';
import { AdminIcon } from './svg/AdminIcon.js';

export * from './svg/Logo.js';

export const FilterIcon = () => {
  return <Funnel />;
};

interface ISHXIcon extends IconProps {
  type:
    | 'bible'
    | 'bibleopen'
    | 'bibleclosed'
    | 'christianbook'
    | 'church'
    | 'journal'
    | 'newtestament'
    | 'oldtestament'
    | 'podcast'
    | 'pray'
    | 'logo'
    | 'do'
    | 'readinglist'
    | 'admin';
}
export const SHXIcon = ({ size = '1em', color = 'currentColor', type, className }: ISHXIcon) => {
  const props: IIconProps = { size, color, className };

  switch (type) {
    case 'church': {
      return ChurchIcon(props);
    }
    case 'journal': {
      return JournalIcon(props);
    }
    case 'pray': {
      return PrayIcon(props);
    }
    case 'newtestament': {
      return NTIcon(props);
    }
    case 'oldtestament': {
      return OTIcon(props);
    }
    case 'bible': {
      return BibleIcon(props);
    }
    case 'bibleopen': {
      return BibleOpenIcon(props);
    }
    case 'bibleclosed': {
      return BibleIcon(props);
    }
    case 'christianbook': {
      return ChristianBookIcon(props);
    }
    case 'podcast': {
      return PodcastIcon(props);
    }
    case 'logo': {
      return Logo(props);
    }
    case 'do': {
      return DoIcon(props);
    }
    case 'readinglist': {
      return ReadingListIcon(props);
    }
    case 'admin': {
      return AdminIcon(props);
    }
  }
};
