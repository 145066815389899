import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/soulhx-styles.scss';
import { App } from './components/App.js';
import store from './stores/Store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';
import './i18n.js';
import { updateNewVersionReload } from '@soulhx/client-store';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    store.dispatch(updateNewVersionReload(true));
  },
});
