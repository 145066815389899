import React from 'react';
import { type IIconProps } from '../../dm/icons.js';

export const DoIcon = ({ color, size, className }: IIconProps) => {
  return (
    <svg
      fill={color}
      viewBox="0 0 16 16"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          id="Path_151"
          d="M38.5,15H38V5.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V8H31.5a.5.5,0,0,0-.5.5V11H28.5a.5.5,0,0,0-.5.5V13H25.5a.5.5,0,0,0-.5.5V15H24V.5a.5.5,0,0,0-1,0v15a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,0-1ZM26,14h2v1H26Zm3-.5V12h2v3H29Zm3-2V9h2v6H32ZM35,15V6h2v9Zm-9.854-3.146a.5.5,0,0,1,0-.708L35.293,1H33.5a.5.5,0,0,1,0-1h3a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0V1.707L25.854,11.854a.5.5,0,0,1-.708,0Z"
          transform="translate(-23)"
         />
      </g>
    </svg>
  );
};
