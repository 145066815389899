import { fetchBaseQuery, type BaseQueryFn, type FetchArgs, type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { updateToken } from '@soulhx/client-store';
import { type RootState } from '../../stores/Store.js';

const baseUrl = `/api`;

export const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, api) => {
      const { getState } = api;
      const token = (getState() as RootState).persistedUI.token;

      if (token) {
        headers.set('Authorization', `bearer ${token}`);
      }

      return headers;
    },
    responseHandler: 'content-type',
  });
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(updateToken(''));
    window.location.reload();
  }

  return result;
};
