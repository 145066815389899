import { type SearchPassageParams } from '../dm/Passage.js';

/**
 * Helper function to take in a `SearchPassageParams` object and
 * return a URL with query params to call the API.
 *
 * @param params Search params
 * @param full Whether this should be a full URL (true/default) or relative
 * @returns String for the URL to get passages
 */
export const getPassagesUrlFromParams = (params: SearchPassageParams, full = true): string => {
  const urlParts: string[] = [];

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push('/read/p');

  urlParts.push('?s=', params.sort);

  if (params.page) {
    urlParts.push('&p=', params.page.toString());
  }

  return urlParts.join('');
};
