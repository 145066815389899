import { type RendererExtension, type TokenizerExtension } from 'marked';

export const HighlightExtension: TokenizerExtension | RendererExtension = {
  name: 'HighlightExtension',
  level: 'inline',
  start(src) {
    return src.match(/==/)?.index || -1;
  },
  tokenizer(src) {
    const rule = /^==([^=]+)==/;
    const match = rule.exec(src);
    if (!match) {
      return;
    }

    const token = {
      type: 'HighlightExtension',
      raw: match[0],
      markText: match[1],
      tokens: [],
    };
    this.lexer.inline(token.markText, token.tokens);
    return token;
  },
  renderer(token) {
    return `<mark>${this.parser.parseInline(token.tokens || token.markText)}</mark>`;
  },
};
