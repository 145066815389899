import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, type FormikHelpers, type FormikProps } from 'formik';
import { useLoginMutation } from '../../services/SecurityService.js';
import { type LoginAttributes } from '@soulhx/fs-common';
import { useDispatch } from 'react-redux';
import { updateToken } from '@soulhx/client-store';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const schema = yup.object({
  loginUsername: yup.string().required('login.loginFields.username.invalid'),
  loginPassword: yup.string().required('login.loginFields.password.invalid'),
});
type ValuesSchema = yup.InferType<typeof schema>;

export const Login = () => {
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const formSubmit = async (values: ValuesSchema, { setSubmitting }: FormikHelpers<ValuesSchema>) => {
    setSubmitting(true);

    const credentials: LoginAttributes = {
      username: values.loginUsername.trim(),
      password: values.loginPassword.trim(),
    };

    login(credentials)
      .unwrap()
      .then((response) => {
        setSubmitting(false);
        dispatch(updateToken(response.token));
      })
      .catch((error) => {
        if (error.status === 401) {
          setErrorMessage(t('login.loginError'));
        } else {
          setErrorMessage(t('login.submitError'));
        }
        setSubmitting(false);
      });
  };

  const initialValues: ValuesSchema = {
    loginUsername: '',
    loginPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={formSubmit}
      validateOnBlur
      validateOnChange
    >
      {(fp: FormikProps<ValuesSchema>) => (
        <Form noValidate onSubmit={fp.handleSubmit}>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form.Group>
            <Form.Label>{t('login.loginFields.username.label')}</Form.Label>
            <Form.Control
              placeholder={t('login.loginFields.username.placeholder')}
              name="loginUsername"
              value={fp.values.loginUsername}
              onChange={(e) => {
                fp.setFieldTouched('loginUsername', true, true);
                fp.handleChange(e);
              }}
              onBlur={fp.handleBlur}
              type="text"
              isValid={fp.touched.loginUsername && !fp.errors.loginUsername}
              isInvalid={fp.touched.loginUsername && !!fp.errors.loginUsername}
              tabIndex={0}
            />
            <Form.Control.Feedback type="invalid">{t(fp.errors.loginUsername!)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label>{t('login.loginFields.password.label')}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t('login.loginFields.password.placeholder')}
              name="loginPassword"
              value={fp.values.loginPassword}
              onChange={(e) => {
                fp.setFieldTouched('loginPassword', true, true);
                fp.handleChange(e);
              }}
              onBlur={fp.handleBlur}
              isValid={fp.touched.loginPassword && !fp.errors.loginPassword}
              isInvalid={fp.touched.loginPassword && !!fp.errors.loginPassword}
              tabIndex={0}
            />
            <Form.Control.Feedback type="invalid">{t(fp.errors.loginPassword!)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-2">
            <Button variant="primary" type="submit" disabled={fp.isSubmitting || !fp.isValid} tabIndex={0}>
              {t('login.loginBtn')}
            </Button>
          </Form.Group>

          <Form.Group className="mt-2">
            <NavLink aria-label={t('login.changePwd')} to="/changePwd" tabIndex={0}>
              {t('login.changePwd')}
            </NavLink>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};
