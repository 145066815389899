import { createApi } from '@reduxjs/toolkit/query/react';
import { type UserAttributes, type AuthResponse } from '@soulhx/fs-common';
import { customFetchBase } from './helpers/customFetchBase.js';

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: customFetchBase,
  tagTypes: ['user', 'login', 'settings'],
  endpoints: (builder) => ({
    logout: builder.mutation<AuthResponse, void>({
      query: () => ({ url: '/auth/lo', method: 'POST' }),
      invalidatesTags: ['login'],
    }),
    userDetails: builder.query<UserAttributes, void>({
      query: () => '/auth/u?l=user',
      providesTags: ['user'],
    }),
    updateUserBase: builder.mutation<UserAttributes, UserAttributes>({
      query: (user) => ({
        url: '/auth/u/b',
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});

export const { useUserDetailsQuery, useUpdateUserBaseMutation, useLogoutMutation } = userApi;
