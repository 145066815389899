import React, { type ReactNode, useCallback, useEffect, useRef } from 'react';

interface IInfiniteScroll {
  load: () => void;
  hasMore: boolean;
  loader: ReactNode;
  children?: ReactNode;
  endMessage?: ReactNode;
}
export const InfiniteScroll = ({ load, hasMore, loader, children, endMessage }: IInfiniteScroll) => {
  const sentinalRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      // check if the sentinal element is intersecting and, if so, call load
      if (entries[0].isIntersecting && hasMore) {
        load();
      }
    },
    [load, hasMore]
  );

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    if (sentinalRef.current) {
      observerRef.current.observe(sentinalRef.current);
    }

    //clean up when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [load, handleIntersect]);

  useEffect(() => {
    //when the hasMore prop changes, disconnect the previous observer and reattach it to the new sentinal element
    if (observerRef.current && sentinalRef.current) {
      observerRef.current.disconnect();
      observerRef.current.observe(sentinalRef.current);
    }
  }, [hasMore]);

  return (
    <div>
      {children}
      <div ref={sentinalRef}>{hasMore && loader}</div>
      {!hasMore && endMessage}
    </div>
  );
};
