import { type RendererExtension, type TokenizerExtension, type Tokens } from 'marked';
import { getFormattedRef, isRefValid } from '@soulhx/fs-common';
import { escapeForURL } from '@soulhx/fs-common';

export const bibleLinkRE = /^\[\|([^(|]+)(?:\s+\(([^)]*)\))?\|([^;\]]*)(?:;([^\]]+))?\]/;

interface IGetUrl {
  context?: string;
  version?: string;
  includeVerses?: boolean;
}
export const getBGUrl = (
  passage: string,
  { context = undefined, version = undefined, includeVerses = true }: IGetUrl = {}
): string => {
  const searchString = escapeForURL(getFormattedRef(passage, { context, includeVerses }));

  const linkUrlStrings: string[] = [];
  linkUrlStrings.push('https://www.biblegateway.com/passage/?search=');
  linkUrlStrings.push(searchString);

  if (version) {
    linkUrlStrings.push('&version=', escapeForURL(version));
  }

  const linkUrl = linkUrlStrings.join('');
  return linkUrl;
};

export const BibleLinkExtension = (
  defaultVersion: string | undefined = undefined,
  context: string | undefined = undefined,
  includeVerses: boolean = false
): TokenizerExtension | RendererExtension => {
  return {
    name: 'BibleLinkExtension',
    level: 'inline',
    start(src) {
      return src.match(/\[\|/)?.index || -1;
    },
    tokenizer(src) {
      const match = bibleLinkRE.exec(src);
      if (!match) {
        return;
      }

      const passage = match[1];
      if (!isRefValid(passage, { context })) {
        return;
      }

      const index = match.index;
      const matchedLength = match[0].length;

      /* eslint-disable @typescript-eslint/no-unnecessary-condition */
      const setSimple = match[4] !== undefined && match[4].includes('s');
      const hideVersion = match[4] !== undefined && match[4].includes('h');

      let toDisplay = setSimple
        ? match[2] || match[1]
        : match[2] || getFormattedRef(match[1], { includeVerses, context });
      toDisplay = toDisplay.replace(/([0-9])-([0-9])/, '$1–$2');

      const version = match[3] || defaultVersion;

      const linkUrl = getBGUrl(match[1], { includeVerses, context, version });

      const token: Tokens.Generic = {
        type: 'BibleLinkExtension',
        raw: match[0],
        passage,
        toDisplay,
        version,
        index,
        matchedLength,
        linkUrl,
        hideVersion,
      };

      return token;
    },
    renderer(token) {
      const responseStrings: string[] = [];
      responseStrings.push('<a title="', token.passage);
      if (token.version && !token.hideVersion) responseStrings.push(' (', token.version, ')');
      responseStrings.push('" href="', token.linkUrl, '" target="_blank">');
      responseStrings.push(token.toDisplay);
      if (token.version && !token.hideVersion) responseStrings.push(' (', token.version, ')');
      responseStrings.push('✞</a>');
      const responseString = responseStrings.join('');

      return responseString;
    },
  };
};
