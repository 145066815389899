import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { type INextButton } from '../../../../dm/tutorial.js';
import { MDPreview } from '../../md/index.js';

export const NextButton = ({ setComplete, children, lastOne = false }: INextButton) => {
  const childrenToRender = typeof children === 'string' ? <MDPreview content={children} minimal /> : children;

  return (
    <Stack>
      <Button variant={lastOne ? 'primary' : 'outline-primary'} onClick={setComplete}>
        {childrenToRender}
      </Button>
    </Stack>
  );
};
