import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../dm/Root.js';

export interface IPersistedUISlice {
  token: string;
  collapsedPrayerLists: string[];
  showHoverText: boolean;
}

const initialState: IPersistedUISlice = { token: '', collapsedPrayerLists: [], showHoverText: false };

export const persistedUISlice = createSlice({
  name: 'persistedUI',
  initialState: initialState,
  reducers: {
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetPersistedUIState: (state: IPersistedUISlice) => {
      state.token = '';
    },
    addCollapsedPrayerList: (state, action: PayloadAction<string>) => {
      const newList = state.collapsedPrayerLists.slice();
      newList.push(action.payload);
      state.collapsedPrayerLists = newList;
    },
    deleteCollapsedPrayerList: (state, action: PayloadAction<string>) => {
      const newList = state.collapsedPrayerLists.filter((item) => item !== action.payload);
      state.collapsedPrayerLists = newList;
    },
    setShowHoverText: (state, action: PayloadAction<boolean>) => {
      state.showHoverText = action.payload;
    },
  },
  selectors: {
    getToken: (sliceState) => sliceState.token,
    getCollapsedPrayerLists: (sliceState) => sliceState.collapsedPrayerLists,
    getShowHoverText: (sliceState) => sliceState.showHoverText,
  },
});

export const {
  updateToken,
  resetPersistedUIState,
  addCollapsedPrayerList,
  deleteCollapsedPrayerList,
  setShowHoverText,
} = persistedUISlice.actions;

export const { getToken, getCollapsedPrayerLists, getShowHoverText } = persistedUISlice.getSelectors<RootState>(
  (rootState) => rootState.persistedUI
);
