import React, { lazy, Suspense } from 'react';
import { type ISHXEditor } from '@soulhx/editor';
import { useTranslation } from 'react-i18next';
const SHXEditorLib = lazy(() => import('@soulhx/editor'));

/**
 * Lazy-loading wrapper over the `@soulhx/editor` lib.
 *
 * @param props Props as defined by the SHXEditor lib
 */
export const SHXEditor = (props: Omit<ISHXEditor, 't'>) => {
  const { t } = useTranslation(['shxmd']);

  return (
    <Suspense fallback=". . .">
      <SHXEditorLib t={t} {...props} />
    </Suspense>
  );
};
