import { type RendererExtension, type TokenizerExtension, type Tokens } from 'marked';

const fnRefRE = /^\[\^([^\]]+)\](?!:)/;

export const FootnoteRefExtension: RendererExtension | TokenizerExtension = {
  name: 'FootnoteRefExtension',
  level: 'inline',
  start(src) {
    return src.match(fnRefRE)?.index || -1;
  },
  tokenizer(src) {
    const refMatch = fnRefRE.exec(src);
    if (!refMatch) {
      return;
    }

    const refToken: Tokens.Generic = {
      type: 'FootnoteRefExtension',
      raw: refMatch[0],
      ref: refMatch[1],
    };
    return refToken;
  },
  renderer(token) {
    return `<sup><a href="#user-content-fn-${token.ref}" id="user-content-fnref-${token.ref}">${token.ref}</a></sup>`;
  },
};
