import React, { type ReactNode } from 'react';
import { type MarkdownSubsectionContent } from '../../../dm/tutorial.js';
import { MDPart } from './helpers/MDPart.js';

interface IMarkdownSubSectionBody {
  i18nNS: string;
  i18nSection: string;
  subSection: MarkdownSubsectionContent;
  nextButton: ReactNode;
}
export const MarkdownSubSectionBody = ({ i18nNS, i18nSection, subSection, nextButton }: IMarkdownSubSectionBody) => {
  return (
    <>
      {subSection.parts.map((part) => (
        <MDPart
          i18nLocation={i18nSection}
          i18nNS={i18nNS}
          part={part}
          key={`mdpart-${i18nSection}-${part.i18nLocation}`}
        />
      ))}

      {nextButton}
    </>
  );
};
