import { createApi } from '@reduxjs/toolkit/query/react';
import { type Note, type BaseNote, type SearchNoteParams, type SearchNoteResponse, getAllNotesUrlFromParams } from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';

export interface DeleteNoteResponseInterface {
  success: boolean;
}

const clearNotesCache = async () => {
  const cache = await caches.open('notes');
  const keys = await cache.keys();

  for (const k of keys) {
    if (k.url.includes('?m=')) {
      await cache.delete(k.url);
    }
  }
};

export const notesApi = createApi({
  reducerPath: 'notes',
  baseQuery: customFetchBase,
  tagTypes: ['notes'],
  endpoints: (builder) => ({
    searchNotes: builder.query<SearchNoteResponse[], SearchNoteParams>({
      query: (params) => getAllNotesUrlFromParams(params, false),
      providesTags: ['notes'],
    }),
    getNoteById: builder.query<Note, string>({
      query: (noteId) => `/read/n/${noteId}`,
      providesTags: (result) => (result ? providesList([result!], 'notes') : []),
    }),
    newNote: builder.mutation<Note, BaseNote>({
      query: (note) => ({
        url: '/read/n/',
        method: 'POST',
        body: note,
      }),
      invalidatesTags: ['notes'],
      onQueryStarted: clearNotesCache,
    }),
    updateNote: builder.mutation<Note, Note>({
      query: (note) => ({
        url: `/read/n/${note.id}`,
        method: 'PUT',
        body: note,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const cache = await caches.open('notes');
        const keys = await cache.keys();

        for (const k of keys) {
          if (k.url.includes(id)) {
            await cache.delete(k.url);
          }
        }

        const patchResult = dispatch(
          notesApi.util.updateQueryData('getNoteById', id, (draft) => {
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result) => (result ? providesList([result!], 'notes', { returnList: false }) : []),
    }),
    deleteNote: builder.mutation<DeleteNoteResponseInterface, string>({
      query: (noteId) => ({ url: `/read/n/${noteId}`, method: 'DELETE' }),
      invalidatesTags: ['notes'],
      onQueryStarted: clearNotesCache,
    }),
  }),
});

export const {
  useGetNoteByIdQuery,
  useNewNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useSearchNotesQuery,
  useLazySearchNotesQuery,
} = notesApi;
