import React, { useEffect, useState } from 'react';
import { type PrayerItem, type PrayerListItem } from '@soulhx/fs-common';
import { Card, Col, Container, ListGroup, Row, Stack } from 'react-bootstrap';
import { StandardButton } from '@soulhx/ui-common';
import { TargetDateStatuses, calculateTargetDateStatus } from './helpers/helper-functions.js';
import { useTranslation } from 'react-i18next';
import { addCollapsedPrayerList, deleteCollapsedPrayerList, getCollapsedPrayerLists } from '@soulhx/client-store';
import { useDispatch, useSelector } from 'react-redux';
import { handleInit } from '../../hooks/HandleInit.js';
import {
  useGetItemsForPrayerListQuery,
  useLazyGetItemsForPrayerListQuery,
  useUpdateItemForPrayerListCompleteMutation,
  useUpdatePrayerCompleteMutation,
} from '../../services/PrayerService.js';
import { useUserSetting } from '../../hooks/Setting.js';
import { useNavigate } from 'react-router-dom';
import { MDPreview } from '../common/md/index.js';
import { SHXEditor } from '../common/md/SHXEDitor.js';

interface ISnapshotCard {
  item: PrayerItem;
}
export const SnapshotCard = ({ item }: ISnapshotCard) => {
  const { t } = useTranslation(['pray']);
  const collapsedPrayerLists = useSelector(getCollapsedPrayerLists);
  const dispatch = useDispatch();
  const { settingValue: showCompleted } = useUserSetting<boolean>('prayerShowAllItems');
  const { settingValue: sortOrder } = useUserSetting<string>('prayerSort');
  const { settingValue: betaUseNewEditor } = useUserSetting<boolean>('betaUseNewEditor');
  const [completeItem] = useUpdatePrayerCompleteMutation();
  const [completeListItem] = useUpdateItemForPrayerListCompleteMutation();
  const [getMoreListItems] = useLazyGetItemsForPrayerListQuery();
  const [hasMoreListItems, setHasMoreListItems] = useState(false);
  const navigate = useNavigate();

  const {
    data: listItems,
    isLoading: pliLoading,
    error: pliError,
  } = useGetItemsForPrayerListQuery(
    {
      listId: item.id,
      params: {
        showCompleted,
        sortOrder,
      },
    },
    { skip: !item.isList }
  );

  useEffect(() => {
    if (!item.isList) return;

    getMoreListItems({ listId: item.id, params: { showCompleted, sortOrder, page: 2 } })
      .unwrap()
      .then((response) => {
        if (response.length > 0) setHasMoreListItems(true);
      });
  }, [getMoreListItems, showCompleted, sortOrder, setHasMoreListItems, item.id, item.isList]);

  const resp = handleInit([pliLoading], [pliError]);
  if (resp) return resp;

  if (item.isList && listItems && listItems.length < 1) return null;

  let clazzName = 'prayer-snapshot-card';

  const collapsePrayerList = collapsedPrayerLists.indexOf(item.id) >= 0;

  const dateStatus = calculateTargetDateStatus(item.targetDate);
  if (dateStatus === TargetDateStatuses.yellow) clazzName = 'prayer-snapshot-card-imminent';
  if (dateStatus === TargetDateStatuses.red) clazzName = 'prayer-snapshot-card-overdue';

  return (
    <Card className={clazzName}>
      <Card.Text as="div">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Stack direction="horizontal" gap={1}>
                {!item.isList && (
                  <StandardButton
                    type="complete"
                    done={item.completed}
                    onClick={() => {
                      const newItem: PrayerItem = { ...item, completed: !item.completed };
                      completeItem(newItem);
                    }}
                  />
                )}
                {!betaUseNewEditor && <MDPreview content={item.text} />}
                {betaUseNewEditor && <SHXEditor initialMarkdown={item.text} readOnly />}
                {item.isList && (
                  <StandardButton
                    type="showHide"
                    className="showhide-btn"
                    done={!collapsePrayerList}
                    onClick={() => {
                      if (collapsePrayerList) {
                        dispatch(deleteCollapsedPrayerList(item.id));
                      } else {
                        dispatch(addCollapsedPrayerList(item.id));
                      }
                    }}
                  />
                )}
              </Stack>
            </Col>
          </Row>
          {item.isList && !collapsePrayerList && (
            <ListGroup variant="flush">
              {listItems!.map((listItem) => {
                let clazz: string | undefined = undefined;

                const dateStatus = calculateTargetDateStatus(listItem.targetDate);
                if (dateStatus === TargetDateStatuses.yellow) clazz = 'lgi-imminent';
                if (dateStatus === TargetDateStatuses.red) clazz = 'lgi-overdue';

                return (
                  <ListGroup.Item className={clazz} key={listItem.id}>
                    <Stack direction="horizontal" gap={1}>
                      <StandardButton
                        type="complete"
                        itemType={t('pray:listItemType')}
                        done={listItem.completed}
                        onClick={() => {
                          const newItem: PrayerListItem = { ...listItem, completed: !listItem.completed };
                          completeListItem(newItem);
                        }}
                      />

                      <MDPreview content={listItem.itemText} />
                    </Stack>
                  </ListGroup.Item>
                );
              })}
              {hasMoreListItems && (
                <ListGroup.Item variant="secondary" action onClick={() => navigate(`/pray/${item.id}`)}>
                  {t('pray:seeFullList')}
                </ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Container>
      </Card.Text>
    </Card>
  );
};
