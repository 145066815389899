import { type TokenizerExtension, type RendererExtension } from 'marked';

export const AllUpperExtension: TokenizerExtension | RendererExtension = {
  name: 'AllUpperExtension',
  level: 'inline',
  start(src) {
    return src.match(/\^\^\^/)?.index || -1;
  },
  tokenizer(src) {
    const rule = /^\^\^\^([^^]+)\^\^\^/;
    const match = rule.exec(src);
    if (!match) {
      return;
    }

    const token = {
      type: 'AllUpperExtension',
      raw: match[0],
      individualWords: match[1].split(' '),
    };
    return token;
  },
  renderer(token) {
    const returnStrings: string[] = [];

    for (let i = 0; i < token.individualWords.length; i++) {
      const theWord = token.individualWords[i];

      // first letter
      returnStrings.push('<span style="text-transform: uppercase;">', theWord.substring(0, 1), '</span>');

      //rest of the word
      returnStrings.push(
        '<span style="text-transform: uppercase; font-size: 1.3ex;">',
        theWord.substring(1, theWord.length),
        '</span>'
      );

      if (i < token.individualWords.length - 1) {
        returnStrings.push(' ');
      }
    }
    const returnString = returnStrings.join('');

    return returnString;
  },
};
