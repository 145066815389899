import { type Verse } from '../../dm/Verse.js';
import bcv from 'bible-passage-reference-parser/js/en_bcv_parser.js';
import OsisFormatter from 'bible-reference-formatter/es6/osisFormatter.js';

const bcv_parser = bcv.bcv_parser;

/**
 * Data type to cover a range of verses, based on OSIS ref
 */
export interface OsisRange {
  startOsisString: string;
  endOsisString: string;
}

interface IsRefValidOptions {
  context?: string | undefined;
}

/**
 * Checks whether a string contains a valid reference (or OSIS). Can
 * optionally be passed **context** in which to check the string.
 *
 * @example
 * isRefValid('Gen.1.1') // true
 * isRefValid('Genesis 1:1') // true
 * isRefValid('verse 1') // false
 * isRefValid('verse 1', { context = undefined }) // false
 * isRefValid('verse 1', { context = 'Matt 1' }) // true
 * isRefValid('1', { context = 'Matt 1' }) // true
 *
 * @param ref Reference to check (OSIS works)
 * @param context Larger context within the check should be performed, if any
 * @returns Boolean indicating validity
 */
export const isRefValid = (ref: string | undefined, { context = undefined }: IsRefValidOptions = {}): boolean => {
  if (!ref) return false;

  const bcv = new bcv_parser();
  bcv.set_options({
    osis_compaction_strategy: 'b',
    book_sequence_strategy: 'include',
    book_alone_strategy: 'full',
    book_range_strategy: 'include',
  });
  const osisString = context ? bcv.parse_with_context(ref, context).osis() : bcv.parse(ref).osis();

  return osisString.length > 0;
};

interface GetOsisForRefOptions {
  context?: string | undefined;
}

/**
 * Takes a string containing a reference and returns a valid OSIS
 * reference for that string (or an empty string if the reference
 * isn't valid).
 *
 * Uses same logic as {@link isRefValid} for context.
 *
 * Can safely be called with a string that's already a valid OSIS
 * reference, in which case that same string will be returned.
 *
 * @param ref The reference (can be OSIS or not)
 * @param context Context for the reference, if any
 * @returns OSIS string, or empty string if no valid match
 */
export const getOsisForRef = (ref: string, { context = undefined }: GetOsisForRefOptions = {}): string => {
  const bcv = new bcv_parser();
  bcv.set_options({
    osis_compaction_strategy: 'bcv',
    book_sequence_strategy: 'include',
    book_alone_strategy: 'full',
    book_range_strategy: 'include',
  });
  const osisString = context ? bcv.parse_with_context(ref, context).osis() : bcv.parse(ref).osis();
  return osisString;
};

interface GetRefForOsisOptions {
  includeVerses?: boolean;
}

/**
 * Internal function to return a formatted reference string for an OSIS
 * string. Can optionally format the returned reference to strip out
 * unnecessary verses.
 *
 * Using mostly OOB settings from `bible-reference-formatter` except always
 * n-dash instead of m-dash.
 *
 * @example
 * // Genesis 1:1–31
 * getRefForOsis('Gen.1.1-Gen.1.31')
 * getRefForOsis('Gen.1.1-Gen.1.31', { includeVerses = true })
 *
 * @example
 * // Genesis 1
 * getRefForOsis('Gen.1.1-Gen.1.31', { includeVerses = false })
 *
 * @example
 * // always Rev 1:1
 * getRefForOsis('Rev.1.1')
 * getRefForOsis('Rev.1.1', { includeVerses = true })
 * getRefForOsis('Rev.1.1', { includeVerses = false })
 *
 * @param osisString String to be formatted
 * @param includeVerses Whether to include "unnecessary" verses
 * @returns Formatted string
 */
const getRefForOsis = (osisString: string, { includeVerses = true }: GetRefForOsisOptions = {}): string => {
  const osisFormatter = new OsisFormatter();
  osisFormatter.setBooks({
    Gen: ['Genesis'],
    Exod: ['Exodus'],
    Lev: ['Leviticus'],
    Num: ['Numbers'],
    Deut: ['Deuteronomy'],
    Josh: ['Joshua'],
    Judg: ['Judges'],
    Ruth: ['Ruth'],
    '1Sam': ['1 Samuel'],
    '2Sam': ['2 Samuel'],
    '1Kgs': ['1 Kings'],
    '2Kgs': ['2 Kings'],
    '1Chr': ['1 Chronicles'],
    '2Chr': ['2 Chronicles'],
    Ezra: ['Ezra'],
    Neh: ['Nehemiah'],
    Esth: ['Esther'],
    Job: ['Job'],
    Ps: ['Psalm', 'Psalms'],
    Prov: ['Proverbs'],
    Eccl: ['Ecclesiastes'],
    Song: ['Song of Solomon'],
    Isa: ['Isaiah'],
    Jer: ['Jeremiah'],
    Lam: ['Lamentations'],
    Ezek: ['Ezekiel'],
    Dan: ['Daniel'],
    Hos: ['Hosea'],
    Joel: ['Joel'],
    Amos: ['Amos'],
    Obad: ['Obadiah'],
    Jonah: ['Jonah'],
    Mic: ['Micah'],
    Nah: ['Nahum'],
    Hab: ['Habakkuk'],
    Zeph: ['Zephaniah'],
    Hag: ['Haggai'],
    Zech: ['Zechariah'],
    Mal: ['Malachi'],
    Matt: ['Matthew'],
    Mark: ['Mark'],
    Luke: ['Luke'],
    John: ['John'],
    Acts: ['Acts'],
    Rom: ['Romans'],
    '1Cor': ['1 Corinthians'],
    '2Cor': ['2 Corinthians'],
    Gal: ['Galatians'],
    Eph: ['Ephesians'],
    Phil: ['Philippians'],
    Col: ['Colossians'],
    '1Thess': ['1 Thessalonians'],
    '2Thess': ['2 Thessalonians'],
    '1Tim': ['1 Timothy'],
    '2Tim': ['2 Timothy'],
    Titus: ['Titus'],
    Phlm: ['Philemon'],
    Heb: ['Hebrews'],
    Jas: ['James'],
    '1Pet': ['1 Peter'],
    '2Pet': ['2 Peter'],
    '1John': ['1 John'],
    '2John': ['2 John'],
    '3John': ['3 John'],
    Jude: ['Jude'],
    Rev: ['Revelation'],
    Tob: ['Tobit'],
    Jdt: ['Judith'],
    GkEsth: ['Greek Esther'],
    EsthGr: ['Greek Esther'],
    AddEsth: ['Additions to Esther'],
    Wis: ['Wisdom of Solomon'],
    Sir: ['Sirach'],
    Bar: ['Baruch'],
    EpJer: ['Epistle of Jeremiah'],
    DanGr: ['Greek Daniel'],
    SgThree: ['Song of the Three Jews'],
    PrAzar: ['Prayer of Azariah'],
    Sus: ['Susanna'],
    Bel: ['Bel and the Dragon'],
    '1Macc': ['1 Maccabees'],
    '2Macc': ['2 Maccabees'],
    '3Macc': ['3 Maccabees'],
    '4Macc': ['4 Maccabees'],
    PrMan: ['Prayer of Manasseh'],
    '1Esd': ['1 Esdras'],
    '2Esd': ['2 Esdras'],
    Ps151: ['Psalm 151'],
    AddPs: ['Psalm 151'],
    // Psalms
    'Ps.$chapters': ['Psalm', 'Psalms'],
    // Ranges
    '1Sam-2Sam': ['1\u20132 Samuel'],
    '1Kgs-2Kgs': ['1\u20132 Kings'],
    '1Chr-2Chr': ['1\u20132 Chronicles'],
    '1Cor-2Cor': ['1\u20132 Corinthians'],
    '1Thess-2Thess': ['1\u20132 Thessalonians'],
    '1Tim-2Tim': ['1\u20132 Timothy'],
    '1Pet-2Pet': ['1\u20132 Peter'],
    '1John-2John': ['1\u20132 John'],
    '1John-3John': ['1\u20133 John'],
    '2John-3John': ['2\u20133 John'],
    '1Macc-2Macc': ['1\u20132 Maccabees'],
    '1Macc-3Macc': ['1\u20133 Maccabees'],
    '1Macc-4Macc': ['1\u20134 Maccabees'],
    '2Macc-3Macc': ['2\u20133 Maccabees'],
    '2Macc-4Macc': ['2\u20134 Maccabees'],
    '3Macc-4Macc': ['3\u20134 Maccabees'],
    '1Esd-2Esd': ['1\u20132 Esdras'],
    // Sequences
    '1Sam,2Sam': ['1 and 2 Samuel'],
    '1Kgs,2Kgs': ['1 and 2 Kings'],
    '1Chr,2Chr': ['1 and 2 Chronicles'],
    '1Cor,2Cor': ['1 and 2 Corinthians'],
    '1Thess,2Thess': ['1 and 2 Thessalonians'],
    '1Tim,2Tim': ['1 and 2 Timothy'],
    '1Pet,2Pet': ['1 and 2 Peter'],
    '1John,2John': ['1 and 2 John'],
    '1John,3John': ['1 and 3 John'],
    '2John,3John': ['2 and 3 John'],
    '1Macc,2Macc': ['1 and 2 Maccabees'],
    '1Macc,2Macc,3Macc': ['1, 2, and 3 Maccabees'],
    '1Macc,2Macc,3Macc,4Macc': ['1, 2, 3, and 4 Maccabees'],
    '1Macc,3Macc': ['1 and 3 Maccabees'],
    '1Macc,3Macc,4Macc': ['1, 3, and 4 Maccabees'],
    '1Macc,4Macc': ['1 and 4 Maccabees'],
    '2Macc,3Macc': ['2 and 3 Maccabees'],
    '2Macc,3Macc,4Macc': ['2, 3, and 4 Maccabees'],
    '2Macc,4Macc': ['2 and 4 Maccabees'],
    '3Macc,4Macc': ['3 and 4 Maccabees'],
    '1Esd,2Esd': ['1 and 2 Esdras'],
  });
  osisFormatter.setOptions({
    ',': '; ',
    'b,c': '; $chapters ',
    'b,v': '; $b ',
    'c,v': '; $c:',
    'v,c': '; $chapters ',
    'v,cv': '; ',
    'v,v': ', ',
    $chapters: ['ch.', 'chs.'],
    $verses: ['v.', 'vv.'],
    singleChapterFormat: 'b',
    '-': '\u2013',
    'b-c': '\u2013$chapters ',
    'b-v': '\u2013$b ',
    'c-v': '\u2013$c:',
    'v-c': '\u2013$chapters ',
    'v-cv': '\u2013',
    'v-v': '\u2013',
    '^c': '$chapters ',
    'b1^v': '$verses ',
    '^v': '$verses ',
  });

  if (includeVerses) {
    return osisFormatter.format(osisString);
  }

  const bcv = new bcv_parser();
  bcv.set_options({
    osis_compaction_strategy: 'b',
    book_sequence_strategy: 'include',
    book_alone_strategy: 'full',
    book_range_strategy: 'include',
  });

  const newOsis = bcv.parse(osisString).osis();
  return osisFormatter.format(newOsis);
};

/**
 * Same as {@link getRefForOsis} (and uses that function under the hood),
 * except that the input is an array of {@link Verse} objects instead of
 * an OSIS string.
 *
 * @param verses Array of verses
 * @returns Formatted string
 */
export const getRefForVerses = (verses: Verse[] | undefined): string => {
  if (!verses || verses.length === 0) {
    return '';
  }

  const osisStrings: string[] = [];
  for (const v of verses) {
    osisStrings.push(v.osis, ',');
  }

  const osis = osisStrings.join('');
  return getRefForOsis(getOsisForRef(osis));
};

/**
 * Parses an OSIS string to pull out one or more **ranges** for that string.
 *
 * @example
 * // single range, where the start and end are both Gen 1:1
 * getRangesForOsis('Gen.1.1')
 *
 * @example
 * // single range, where the start is Gen 1:1
 * // and the end is Gen 1:2
 * getRangesForOsis('Gen.1.1-Gen.1.2')
 *
 * @example
 * // two ranges: Gen 1:1 to 1:2 and Gen 1:5 to 1:5
 * getRangesForOsis('Gen.1.1-Gen.1.2,Gen.1.5')
 *
 * @param rawOsisString The OSIS string, containing one or more ranges
 * @returns Array of objects representing each range
 */
export const getRangesForOsis = (rawOsisString: string): OsisRange[] => {
  if (!isRefValid(rawOsisString)) {
    return [];
  }

  const properlyFormattedOsis = getOsisForRef(rawOsisString);

  const returnArray: OsisRange[] = [];
  const passageArray = properlyFormattedOsis.split(',');

  for (const p of passageArray) {
    const rawRange = p.split('-');

    const range: OsisRange = {
      startOsisString: rawRange[0],
      endOsisString: rawRange[rawRange.length - 1],
    };

    returnArray.push(range);
  }

  return returnArray;
};

interface GetFormattedRefOptions {
  includeVerses?: boolean;
  context?: string | undefined;
}

/**
 * Takes a string (could be OSIS or human-readable) and returns a
 * formatted version.
 *
 * Follows same logic as {@link getRefForOsis} for the `includeVerses`
 * param, and the same logic as {@link isRefValid} for `context`.
 *
 * @param osisOrRef String containing the reference, OSIS or not
 * @param includeVerses Whether "optional" verses should be included in the result
 * @param context Larger context for the reference if any
 * @returns Formatted string for the reference, or an empty string if the ref isn't valid
 */
export const getFormattedRef = (
  osisOrRef: string,
  { includeVerses = true, context = undefined }: GetFormattedRefOptions = {}
): string => {
  if (!isRefValid(osisOrRef, { context })) {
    return '';
  }

  return getRefForOsis(getOsisForRef(osisOrRef, { context }), { includeVerses });
};

/**
 * TypeScript interface for the data returned from the
 * `osis_and_indices()` function in the underlying library
 */
interface OsisAndIndicesResult {
  osis: string;
  translations: string[];
  indices: [number, number];
}

/**
 * Interface for data returned from {@link getAllRefsFromString}
 */
export interface RefFromStringResult {
  osis: string;
  indices: [number, number];
}

interface GetAllRefsFromStringOptions {
  context?: string | undefined;
}

/**
 * Parses a string that may or may not contain valid references and,
 * for each one found, returns details:
 *
 * * The formatted OSIS string, and
 * * Details about where the reference is found in the original
 * string (i.e. start and end character indices)
 *
 * @param osisOrRef Reference string
 * @param context Context (if any)
 * @returns List of 0 or more references
 */
export const getAllRefsFromString = (
  osisOrRef: string,
  { context = undefined }: GetAllRefsFromStringOptions = {}
): RefFromStringResult[] => {
  const bcv = new bcv_parser();
  bcv.set_options({
    osis_compaction_strategy: 'bcv',
    book_sequence_strategy: 'ignore',
    book_range_strategy: 'ignore',
    sequence_combination_strategy: 'separate',
    book_alone_strategy: 'ignore',
    invalid_passage_strategy: 'ignore',
  });

  const bcvResult: OsisAndIndicesResult[] = context
    ? bcv.parse_with_context(osisOrRef, context).osis_and_indices()
    : bcv.parse(osisOrRef).osis_and_indices();
  const response: RefFromStringResult[] = bcvResult.map((item) => ({
    osis: item.osis,
    indices: [item.indices[0], item.indices[1] - 1],
  }));

  return response;
};
