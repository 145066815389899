import React, { type KeyboardEventHandler } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IFilterBox {
  /**
   * Filter text. Empty string acceptable; `undefined` not
   */
  filterString: string;

  /**
   * Called when the component updates the string; e.g. a React
   * `useState` call.
   *
   * @param newValue Value being set by the component
   */
  updateFilterCallback: (newValue: string) => void;

  /**
   * Optional string to use for placeholder / floating text
   */
  placeholder?: string;

  /**
   * Optional handler to notify the calling component on key
   * up events
   */
  onKeyUp?: KeyboardEventHandler;
}
/**
 * Standard UI for a text filter
 */
export const FilterBox = ({ filterString, updateFilterCallback, placeholder, onKeyUp }: IFilterBox) => {
  const { t } = useTranslation();

  return (
    <FloatingLabel controlId="filterboxInput" label={placeholder || t('commonComponents.filterBox.placeholder')}>
      <Form.Control
        type="search"
        value={filterString}
        onChange={(e) => updateFilterCallback(e.currentTarget.value)}
        onKeyUp={onKeyUp}
      />
    </FloatingLabel>
  );
};
