import { useCallback, useMemo } from 'react';
import { getDefaultForSetting } from '@soulhx/user-settings';
import { type UserSetting } from '@soulhx/fs-common';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettings, setUserSetting, getToken } from '@soulhx/client-store';

export const useUserSetting = <Type extends string | boolean>(
  settingName: string
): {
  /**
   * The value; its default (if `isLoading`), or the last value
   * retrieved from the server
   */
  settingValue: Type;

  /**
   * Function to update the value via the API
   * @param newVal New value to set on the server
   */
  update(newVal: Type): Promise<void>;
} => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const reduxSettings = useSelector(getUserSettings);

  const getTypeFromObj = useCallback((setting: UserSetting) => {
    if (setting.settingBoolValue !== undefined) {
      return setting.settingBoolValue as Type;
    }

    return setting.settingStringValue as Type;
  }, []);

  const val = useMemo(() => {
    const index = reduxSettings.findIndex((s) => s.settingId === settingName);
    if (index >= 0) return getTypeFromObj(reduxSettings[index]);

    return getDefaultForSetting(settingName) as Type;
  }, [getTypeFromObj, reduxSettings, settingName]);

  const update = useCallback(
    async (newVal: Type) => {
      if (newVal === val || token.length < 1) return;

      const newSetting: UserSetting = { settingId: settingName };
      if (typeof newVal === 'boolean') {
        newSetting.settingBoolValue = newVal as boolean;
      } else {
        newSetting.settingStringValue = newVal as string;
      }

      fetch('/api/auth/u/s', {
        method: 'PUT',
        body: JSON.stringify(newSetting),
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }).then(async (response) => {
        if (!response.ok) return;

        const newObj: UserSetting = await response.json();
        const newType = getTypeFromObj(newObj);
        if (newType !== val) {
          dispatch(setUserSetting(newObj));
        }

        fetch('/api/auth/u/s', { headers: { Authorization: `bearer ${token}`, 'Content-Type': 'application/json' } });
      });
    },
    [token, val, settingName, getTypeFromObj, dispatch]
  );

  return { settingValue: val, update };
};
