import { type PaginatedPliRequest, type PaginatedPrayersRequest } from '../dm/Prayer.js';

/**
 * Simple helper that just converts a bool to `t` or `f`
 * @param value Boolean input
 * @returns `t` or `f`
 */
const b2ss = (value: boolean): string => {
  if (value === false) return 'f';

  return 't';
};

export const getPaginatedPrayerUrlFromParams = (searchParams: PaginatedPrayersRequest, full = true): string => {
  const urlParts: string[] = [];

  if (full) urlParts.push('/api');
  urlParts.push('/pray/');

  urlParts.push('?s=', searchParams.sortOrder);
  urlParts.push('&c=', b2ss(searchParams.showCompleted));

  if (searchParams.page) {
    urlParts.push('&p=', searchParams.page.toString());
  }

  if (searchParams.searchText) {
    urlParts.push('&q=', searchParams.searchText.replace(/\s/g, '+'));
  }

  if (searchParams.filterEmptyLists !== undefined) {
    urlParts.push('&el=', b2ss(searchParams.filterEmptyLists));
  }

  return urlParts.join('');
};

/**
 * Same as {@link getPaginatedPrayerUrlFromParams} but for list items
 */
export const getPaginatedPliFromParams = (
  prayerListId: string,
  searchParams: PaginatedPliRequest,
  full = true
): string => {
  const urlParts: string[] = [];

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push(`/pray/${prayerListId}/listItems`);
  urlParts.push('?s=', searchParams.sortOrder);
  urlParts.push('&c=', b2ss(searchParams.showCompleted));

  if (searchParams.page) {
    urlParts.push('&p=', searchParams.page.toString());
  }

  if (searchParams.searchText) {
    urlParts.push('&q=', searchParams.searchText.replace(/\s/g, '+'));
  }

  return urlParts.join('');
};
