import { type ActionEntry, type SpecialActionTypeIds } from '@soulhx/fs-common';
import {
  useGetActionsForDayQuery,
  useGetActionsForMonthQuery,
  useGetSpecialTypeIDsQuery,
} from '../services/ActionsService.js';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { type TFunction } from 'i18next';

/**
 * Expected response from `useGetActionEntriesForMonth` and
 * `useGetActionEntriesForDay`. Just mimics the RTQ query
 * interface.
 */
export interface GetEntriesResponse {
  entries: ActionEntry[] | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  entriesError: any;
  entriesLoading: boolean;
}

/**
 * Options to refine how the data is processed
 */
interface GetAEOptions {
  /**
   * Whether long i18n names should be used (the default)
   */
  longNames?: boolean;
}

/**
 * Helper function to pre-process data from the "get action
 * entries" RTQ queries, to put the data in a more useable form
 * for the UI (translation). To the calling code, it should be
 * very similar to calls to the "raw" RTQ services, except that
 * the data returned is pre-processed.
 *
 * @param data Raw data response from RTQ
 * @param error Raw error response from RTQ
 * @param isLoading Raw isLoading response from RTQ
 * @param longNames Whether long or short names should be used
 * @returns Same type of data as would be expected from RTQ
 */
const getProcessedEntries = (
  data: ActionEntry[],
  longNames: boolean,
  specialIds: SpecialActionTypeIds,
  t: TFunction<'translation', undefined>
): ActionEntry[] => {
  const getDisplayName = (entry: ActionEntry) => {
    const i18nPrefix = longNames === true ? 'do:actionTypes.long.' : 'do:actionTypes.short.';

    if (entry.actionType === specialIds.book) {
      return t(i18nPrefix + 'book');
    }
    if (entry.actionType === specialIds.church) {
      return t(i18nPrefix + 'church');
    }
    if (entry.actionType === specialIds.journal) {
      return t(i18nPrefix + 'journal');
    }
    if (entry.actionType === specialIds.longNT) {
      return t(i18nPrefix + 'longNT');
    }
    if (entry.actionType === specialIds.longOT) {
      return t(i18nPrefix + 'longOT');
    }
    if (entry.actionType === specialIds.podcast) {
      return t(i18nPrefix + 'podcast');
    }
    if (entry.actionType === specialIds.prayer) {
      return t(i18nPrefix + 'prayer');
    }
    if (entry.actionType === specialIds.scripture) {
      return t(i18nPrefix + 'scripture');
    }
    if (entry.actionType === specialIds.shortNT) {
      return t(i18nPrefix + 'shortNT');
    }
    if (entry.actionType === specialIds.shortOT) {
      return t(i18nPrefix + 'shortOT');
    }

    return entry.displayName;
  };

  const theArray: ActionEntry[] = data.map((ae) => ({
    id: ae.id,
    actionType: ae.actionType,
    completed: ae.completed,
    entryDate: ae.entryDate,
    displayName: getDisplayName(ae),
  }));

  return theArray;
};

/**
 * Hook to wrap calls to the RTQ `useGetActionsForMonthQuery` query;
 * heavy lifting performed by {@link useProcessedEntries}.
 */
export const useGetActionEntriesForMonth = (
  year: string,
  month: string,
  { longNames = true }: GetAEOptions = {}
): GetEntriesResponse => {
  const { data, error, isLoading } = useGetActionsForMonthQuery({ year, month });
  const { data: specialIds, error: specialTypesErr, isLoading: specialTypesLoading } = useGetSpecialTypeIDsQuery();
  const { t } = useTranslation();

  const responseEntries: ActionEntry[] = useMemo(() => {
    if (!data) return [];
    if (!specialIds) return data;

    return getProcessedEntries(data, longNames, specialIds, t);
  }, [data, specialIds, t, longNames]);

  if (isLoading || specialTypesLoading) {
    return { entries: responseEntries, entriesError: error, entriesLoading: true };
  }
  if (error) {
    return { entries: responseEntries, entriesError: error, entriesLoading: isLoading };
  }
  if (specialTypesErr) {
    return { entries: responseEntries, entriesError: specialTypesErr, entriesLoading: isLoading };
  }
  return { entries: responseEntries, entriesError: error, entriesLoading: isLoading };
};

/**
 * Hook to wrap calls to the RTQ `useGetActionsForDayQuery` query;
 * heavy lifting performed by {@link useProcessedEntries}.
 */
export const useGetActionEntriesForDay = (
  year: string,
  month: string,
  day: string,
  { longNames = true }: GetAEOptions = {}
): GetEntriesResponse => {
  const { data, error, isLoading } = useGetActionsForDayQuery({ year, month, day });
  const { data: specialIds, error: specialTypesErr, isLoading: specialTypesLoading } = useGetSpecialTypeIDsQuery();
  const { t } = useTranslation();

  const responseEntries: ActionEntry[] = useMemo(() => {
    if (!data) return [];
    if (!specialIds) return data;

    return getProcessedEntries(data, longNames, specialIds, t);
  }, [data, specialIds, longNames, t]);

  if (isLoading || specialTypesLoading) {
    return { entries: responseEntries, entriesError: error, entriesLoading: true };
  }
  if (error) {
    return { entries: responseEntries, entriesError: error, entriesLoading: isLoading };
  }
  if (specialTypesErr) {
    return { entries: responseEntries, entriesError: specialTypesErr, entriesLoading: isLoading };
  }

  return { entries: responseEntries, entriesError: error, entriesLoading: isLoading };
};
