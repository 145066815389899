import { createApi } from '@reduxjs/toolkit/query/react';
import {
  type GetUserStatsResponse,
  type PendingUser,
  type PendingUserSearchAttributes,
  type UserAttributes,
  type UserSearchAttributes,
  getPendUsr4AdminUrl,
  getUsr4AdminUrl,
} from '@soulhx/fs-common';
import { customFetchBase } from './helpers/customFetchBase.js';

export const adminApi = createApi({
  reducerPath: 'admin',
  baseQuery: customFetchBase,
  tagTypes: ['admin-users'],
  endpoints: (builder) => ({
    getUserStats: builder.query<GetUserStatsResponse, void>({
      query: () => '/auth/admin/stats',
    }),
    getPaginatedUserList: builder.query<UserAttributes[], UserSearchAttributes>({
      query: (searchParams) => getUsr4AdminUrl(searchParams, false),
    }),
    getPaginatedPendingUserList: builder.query<PendingUser[], PendingUserSearchAttributes>({
      query: (searchParams) => getPendUsr4AdminUrl(searchParams, false),
    }),
    getUser: builder.query<UserAttributes, string>({
      query: (userId) => `/auth/admin/u/${userId}`,
    }),
    updateUser: builder.mutation<UserAttributes, UserAttributes>({
      query: (user) => ({
        url: '/auth/admin/u/update',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['admin-users'],
    }),
    clearUserSettings: builder.mutation<UserAttributes, string>({
      query: (user) => ({
        url: `/auth/admin/clearUserSettings/${user}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetUserStatsQuery,
  useGetPaginatedPendingUserListQuery,
  useGetPaginatedUserListQuery,
  useLazyGetPaginatedPendingUserListQuery,
  useLazyGetPaginatedUserListQuery,
  useClearUserSettingsMutation,
} = adminApi;
