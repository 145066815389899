import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useUserDetailsQuery } from '../../services/UserService.js';
import { getDateShowingInActions, updateDateShowingInActions } from '@soulhx/client-store';
import { useGetActionTypes } from '../../hooks/GetActions.js';
import { useGetActionEntriesForMonth } from '../../hooks/GetActionEntries.js';
import { handleInit } from '../../hooks/HandleInit.js';
import { type CalendarCalloutItem } from '../common/calendar/helpers/dm.js';
import { CalendarWidget } from '../common/calendar/CalendarWidget.js';

interface ICalendarView {
  close?: () => void;
}
export const CalendarView = ({ close }: ICalendarView) => {
  const dateToShow = DateTime.fromISO(useSelector(getDateShowingInActions));
  const [dateForComponent, setDateForComponent] = useState<DateTime>(dateToShow);
  const dispatch = useDispatch();
  const { entries, entriesError, entriesLoading } = useGetActionEntriesForMonth(
    dateForComponent.year.toString(),
    dateForComponent.month.toString()
  );
  const { data: userData, error: userError, isLoading: userLoading } = useUserDetailsQuery();
  const { actionTypes, actionTypeError, actionTypesLoading } = useGetActionTypes();

  const resp = handleInit(
    [entriesLoading, userLoading, actionTypesLoading],
    [entriesError, userError, actionTypeError]
  );
  if (resp) return resp;

  const callouts: CalendarCalloutItem[] = [];
  const availableTypes = actionTypes!.map((t) => t.displayName);
  for (const t of availableTypes) {
    callouts.push({ name: t, dates: new Set<string>() });
  }

  for (const a of entries!) {
    const dateToUse = DateTime.fromISO(a.entryDate).toISODate()!;
    const indexOfItem = callouts.findIndex((i) => i.name === a.displayName);
    if (indexOfItem < 0) continue;

    callouts[indexOfItem].dates.add(dateToUse);
  }

  return (
    <CalendarWidget
        dateToShow={dateToShow}
        selectNewDate={(value) => {
          const dateToDispatch = DateTime.fromJSDate(value);
          dispatch(updateDateShowingInActions(dateToDispatch.toISO()!));
          if (close) close();
        }}
        startDateChange={(activeStartDate) => {
          const monthDateToShow = DateTime.fromJSDate(activeStartDate);
          setDateForComponent(monthDateToShow);
        }}
        maxDate={DateTime.now()}
        minDate={DateTime.fromISO(userData!.signupDate)}
        callouts={callouts}
        settingName="doCalendarActions"
      />
  );
};
