import React from 'react';
import { useUserSetting } from '../../../hooks/Setting.js';
import { Alert, Col, Row } from 'react-bootstrap';
import { SHXEditor } from '../../common/md/SHXEDitor.js';

interface IShxServerMessage {
  children: string;
  id: string;
  dismissible?: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}
export const ShxServerMessage = ({ children, id, dismissible = true, variant = 'info' }: IShxServerMessage) => {
  const { settingValue: showMessage, update: updateShowMessage } = useUserSetting<boolean>(
    `helpText.serverMessages.${id}`
  );

  if (!showMessage && dismissible) return null;

  return (
    <Row>
      <Col xs="12" className="p-0">
        <Alert
          variant={variant}
          className="shx-helptext m-0 mb-1"
          dismissible={dismissible}
          onClose={() => {
            updateShowMessage(!showMessage);
          }}
        >
          <SHXEditor readOnly initialMarkdown={children} />
        </Alert>
      </Col>
    </Row>
  );
};
