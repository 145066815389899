import { type PendingUserSearchAttributes, type UserSearchAttributes } from '../dm/User.js';

const b2ss = (value: boolean): string => {
  return value === false ? 'f' : 't';
};

export const getUsr4AdminUrl = (searchParams: UserSearchAttributes, full = true): string => {
  const urlParts: string[] = [];

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push('/auth/admin/userList');

  urlParts.push('?s=', searchParams.sortBy);
  urlParts.push('&sa=', b2ss(searchParams.sortAscending));

  if (searchParams.page) {
    urlParts.push('&p=', searchParams.page.toString());
  }

  if (searchParams.searchParams.displayName) {
    urlParts.push('&d=', searchParams.searchParams.displayName);
  }

  if (searchParams.searchParams.email) {
    urlParts.push('&e=', searchParams.searchParams.email);
  }

  if (searchParams.searchParams.isAdmin !== undefined) {
    urlParts.push('&a=', b2ss(searchParams.searchParams.isAdmin));
  }

  if (searchParams.searchParams.username) {
    urlParts.push('&u=', searchParams.searchParams.username);
  }

  return urlParts.join('');
};

export const getPendUsr4AdminUrl = (searchParams: PendingUserSearchAttributes, full = true): string => {
  const urlParts: string[] = [];

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push('/auth/admin/pendingUsers');

  urlParts.push('?s=', searchParams.sortBy);
  urlParts.push('&sa=', b2ss(searchParams.sortAscending));

  if (searchParams.page) {
    urlParts.push('&p=', searchParams.page.toString());
  }

  if (searchParams.searchParams.displayName) {
    urlParts.push('&d=', searchParams.searchParams.displayName);
  }

  if (searchParams.searchParams.email) {
    urlParts.push('&e=', searchParams.searchParams.email);
  }

  if (searchParams.searchParams.registrationToken) {
    urlParts.push('&t=', searchParams.searchParams.registrationToken);
  }

  if (searchParams.searchParams.username) {
    urlParts.push('&u=', searchParams.searchParams.username);
  }

  return urlParts.join('');
};
