import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from './helpers/customFetchBase.js';
import { type BaseServerMessage, type ServerMessage } from '@soulhx/fs-common';

export interface DeleteServerMessage {
  success: boolean;
}

export const commsApi = createApi({
  reducerPath: 'comms',
  baseQuery: customFetchBase,
  tagTypes: ['serverMessages'],
  endpoints: (builder) => ({
    newServerMessage: builder.mutation<ServerMessage, BaseServerMessage>({
      query: (body) => ({
        url: '/comms/sm',
        body: body,
        method: 'POST',
      }),
      invalidatesTags: ['serverMessages'],
    }),
    getAllServerMessages: builder.query<ServerMessage[], void>({
      query: () => '/comms/sm',
      providesTags: ['serverMessages'],
    }),
    deleteServerMessage: builder.mutation<DeleteServerMessage, string>({
      query: (id) => ({ url: `/comms/sm/${id}`, method: 'DELETE' }),
      invalidatesTags: ['serverMessages'],
    }),
    updateServerMessage: builder.mutation<ServerMessage, ServerMessage>({
      query: (item) => ({
        url: `/comms/sm/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: ['serverMessages'],
    }),
  }),
});

export const {
  useNewServerMessageMutation,
  useGetAllServerMessagesQuery,
  useDeleteServerMessageMutation,
  useUpdateServerMessageMutation,
} = commsApi;
